<template>
  <div v-if="$store.state.fastPermissoes.ativo == 'S' && ($store.state.fastPermissoes.administrador == 'S')">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/unidades-curriculares'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/unidades-curriculares')"
              >Unidades curriculares</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/unidade-curricular/' + $route.params.id_curso)"
              >Gerenciamento da UC {{ fast_curso.nome_curso }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + $route.params.id_curso + '/aulas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + $route.params.id_curso + '/aulas')"
              >Aulas</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Atividade</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Unidade curricular
                  <span v-if="fast_curso.nome_curso">{{ fast_curso.nome_curso }}</span>
                  <span v-else>...</span>
                </h1>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                    <h3 class="aluno_font_color">
                      Aula
                      <span>{{ fast_aula.sequencia }} - {{ fast_aula.titulo_aula }}</span>
                    </h3>
                  </div>
                  <div
                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                    class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right"
                  >
                    <button
                      class="btn btn-danger btn-sm mt-2 ml-2"
                      @click="showModal('modalExcluirAtividade')"
                    >
                      <small>Excluir atividade</small>
                    </button>
                    <button
                      class="btn btn-primary mt-2 ml-2 mb-2"
                      @click.prevent="showModal('modalCriarAtividade')"
                    >
                      <small>+ &nbsp; Adicionar atividade</small>
                    </button>
                  </div>
                </div>
                <div class="row mt-2 mb-4">
                  <div class="col-12">
                    <img :src="require('@/assets/images/form-effect-01.png')">
                  </div>
                </div>
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12">
                  <vue-step
                    :now-step="nowStep"
                    :step-list="stepList"
                    @selected="(item) => selecionaAtividadeStep(item)"
                  />
                </div>
                <div class="col-12 text-center mt-4">
                  <h4>
                    <span class="aluno_font_color">Atividade {{ fast_atividade.sequencia }} - {{ formataTipoAtividade(fast_atividade.tipo_atividade) }}</span>
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="h6 mt-4 aluno_font_color">Título da atividade</label>
                    <input
                      v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                      v-model="fast_atividade.titulo_atividade"
                      class="form-control"
                      type="text"
                      placeholder="Titulo da atividade"
                      @keyup.prevent="fast_atividade.alteracao_pendente = true"
                    >
                    <input
                      v-else
                      type="text"
                      class="form-control"
                      :value="fast_atividade.titulo_atividade"
                      readonly
                    >
                    <label class="h6 mt-4 aluno_font_color">Detalhes da atividade</label>
                    <textarea
                      v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                      v-model="fast_atividade.detalhe_atividade"
                      class="form-control"
                      placeholder="Descrição do atividade"
                      @keyup.prevent="fast_atividade.alteracao_pendente = true"
                    />
                    <textarea
                      v-else
                      v-model="fast_atividade.detalhe_atividade"
                      class="form-control"
                      rows="5"
                      readonly
                    />
                    <div class="row">
                      <div
                        v-if="fast_atividade.alteracao_pendente"
                        class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                      >
                        <button
                          class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                          @click.prevent="confirmaAlteracoesAtividades()"
                        >
                          <small>
                            <i
                              class="fa fa-floppy-o"
                              aria-hidden="true"
                            />
                            &nbsp;Salvar alterações
                          </small>
                        </button>
                        <button
                          class="btn btn-secondary btn-sm mt-2 ml-2"
                          @click.prevent="getAulaAtividade()"
                        >
                          <small>
                            <i
                              class="fa fa-ban"
                              aria-hidden="true"
                            />
                            &nbsp;Cancelar
                          </small>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-2 text-center">
                        <label class="h6 mt-4 aluno_font_color">Atividade publicada</label>
                        <div class="fd-app-meus-cursos mt-0">
                          <div class="fd-app-curso-aulas-list mt-0">
                            <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                              <label
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                class="switch"
                              >
                                <input
                                  v-if="fast_atividade.publicada"
                                  type="checkbox"
                                  checked="checked"
                                  @click="chkAtividadePublicada(fast_atividade.publicada)"
                                >
                                <input
                                  v-if="!fast_atividade.publicada"
                                  type="checkbox"
                                  @click="chkAtividadePublicada(fast_atividade.publicada)"
                                >
                                <span class="slider round" />
                              </label>
                              <label v-else>
                                <strong
                                  v-if="fast_atividade.publicada"
                                  class="text-success"
                                >Sim</strong>
                                <strong
                                  v-else
                                  class="text-danger"
                                >Não</strong>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-12 col-lg-2 text-center">
                        <label class="h6 mt-4 aluno_font_color">Incrementa evolução</label>
                        <div class="fd-app-meus-cursos mt-0">
                          <div class="fd-app-curso-aulas-list mt-0">
                            <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                              <label
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                class="switch"
                              >
                                <input
                                  v-if="fast_atividade.incrementa_evolucao"
                                  type="checkbox"
                                  checked="checked"
                                  @click="chkIncrementaEvolucao(fast_atividade.incrementa_evolucao)"
                                >
                                <input
                                  v-if="!fast_atividade.incrementa_evolucao"
                                  type="checkbox"
                                  @click="chkIncrementaEvolucao(fast_atividade.incrementa_evolucao)"
                                >
                                <span class="slider round" />
                              </label>
                              <label v-else>
                                <strong
                                  v-if="fast_atividade.incrementa_evolucao"
                                  class="text-success"
                                >Sim</strong>
                                <strong
                                  v-else
                                  class="text-danger"
                                >Não</strong>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fast_atividade.tipo_atividade == 'RF'"
                class="row"
              >
                <div class="col-12">
                  <div
                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                    class="form-group"
                  >
                    <uploader
                      :file-status-text="statusText"
                      :options="optionsArquivo"
                      class="uploader-example"
                      @file-success="fileSuccess"
                      @file-added="fileValidation"
                    >
                      <uploader-unsupport />
                      <uploader-drop>
                        <p class="aluno_font_color">
                          Arraste um arquivo PDF/HTML aqui ou
                        </p>
                        <uploader-btn :attrs="restrictArquivo">
                          Clique aqui para selecionar
                        </uploader-btn>
                      </uploader-drop>
                      <uploader-list />
                    </uploader>
                  </div>
                  <div
                    v-if="fast_atividade.referencia_atividade"
                    class="form-group"
                  >
                    <div
                      v-if="fast_atividade.referencia_atividade"
                      class="text-center"
                    >
                      <iframe
                        v-if="fast_atividade.referencia_atividade.split('.').pop() == 'ppt' || fast_atividade.referencia_atividade.split('.').pop() == 'pptx'"
                        :src="'https://docs.google.com/gview?url=' + corrigeLinkConcurseiro(fast_atividade.referencia_atividade) + '&embedded=true'"
                        frameborder="0"
                        allowfullscreen
                        class="w-100"
                        style="min-height: 500px"
                      />
                      <object
                        v-else-if="fast_atividade.referencia_atividade.split('.').pop() == 'pdf'"
                        :data="corrigeLinkConcurseiro(fast_atividade.referencia_atividade)"
                        type="application/pdf"
                        class="w-100"
                        style="min-height: 500px"
                      >
                        <embed
                          :src="corrigeLinkConcurseiro(fast_atividade.referencia_atividade)"
                          type="application/pdf"
                        >
                      </object>
                      <iframe
                        v-else-if="fast_atividade.referencia_atividade.split('.').pop() == 'html'"
                        :src="corrigeLinkConcurseiro(fast_atividade.referencia_atividade)"
                        frameborder="0"
                        allowfullscreen
                        class="w-100"
                        style="min-height: 500px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fast_atividade.tipo_atividade == 'AD'"
                class="row"
              >
                <div class="col-12">
                  <div
                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                    class="form-group"
                  >
                    <uploader
                      :file-status-text="statusText"
                      :options="optionsAudio"
                      class="uploader-example"
                      @file-success="fileSuccess"
                      @file-added="fileValidation"
                    >
                      <uploader-unsupport />
                      <uploader-drop>
                        <p class="aluno_font_color">
                          Arraste um arquivo MP3 aqui ou
                        </p>
                        <uploader-btn :attrs="restrictAudio">
                          Clique aqui para selecionar
                        </uploader-btn>
                      </uploader-drop>
                      <uploader-list />
                    </uploader>
                  </div>
                  <div
                    v-if="fast_atividade.referencia_atividade"
                    class="form-group"
                  >
                    <audio
                      controls
                      class="d-block"
                    >
                      <source
                        :src="fast_atividade.referencia_atividade"
                        type="audio/mpeg"
                      >
                      Seu navegador não suporte o player de audio.
                    </audio>
                  </div>
                </div>
              </div>
              <div
                v-if="fast_atividade.tipo_atividade == 'VA'"
                class="row"
              >
                <div class="col-12">
                  <div
                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                    class="form-group"
                  >
                    <div class="row">
                      <div class="col-12">
                        <label
                          v-if="fast_atividade.referencia_atividade"
                          class="h6 aluno_font_color"
                        >Para substituir o video escolha uma opção:</label>
                        <label
                          v-else
                          class="h6 aluno_font_color"
                        >Para cadastrar um video escolha uma opção:</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-2 mt-2">
                        <div class="text-center">
                          <a
                            href
                            class="text-dark"
                            @click.prevent="showModal('modalCadastrarVideoFile')"
                          >
                            <b-icon-file-earmark-play font-scale="3" />
                            <br>
                            <div class="text-center">
                              <strong class="aluno_font_color">Arquivo mp4</strong>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-2 mt-2">
                        <div class="text-center">
                          <a
                            href
                            class="text-dark"
                            @click.prevent="showModal('modalCadastrarVideoVimeo')"
                          >
                            <b-icon-collection-play-fill font-scale="3" />
                            <br>
                            <div class="text-center">
                              <strong class="aluno_font_color">Vimeo</strong>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-2 mt-2">
                        <div class="text-center">
                          <a
                            href
                            class="text-dark"
                            @click.prevent="showModal('modalCadastrarVideoYoutube')"
                          >
                            <b-icon-collection-play-fill font-scale="3" />
                            <br>
                            <div class="text-center">
                              <strong class="aluno_font_color">Youtube</strong>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="fast_atividade.referencia_atividade"
                    class="form-group"
                  >
                    <div class="video-container">
                      <iframe
                      v-if="fast_atividade.referencia_atividade.indexOf('vimeo.com') != '-1' && fast_atividade.referencia_atividade.split('/')[3] != 'video'"
                        :src="'https://player.vimeo.com/video/' + fast_atividade.referencia_atividade.split('/')[3]"
                        width="560"
                        height="315"
                        frameborder="0"
                      />
                      <iframe
                        v-else
                        :src="fast_atividade.referencia_atividade"
                        width="560"
                        height="315"
                        frameborder="0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fast_atividade.tipo_atividade == 'QZ'"
                class="row"
              >
                <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                  <h4 class="aluno_font_color">
                    Questões do QUIZ ({{ fastAtividadeQuestoes.questoes.length }})
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                  <a
                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                    class="btn btn-primary btn-sm mt-2 ml-2"
                    :href="'/plataforma/' + $route.params.id_plataforma + '/admin/provas?atividade=' + fast_atividade.id_atividade + '&tipo=quiz#questões'"
                  >
                    <small>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                      />
                      &nbsp;Gerenciar questões
                    </small>
                  </a>
                </div>
                <div
                  v-if="fastAtividadeQuestoes.questoes.length"
                  class="col-12"
                >
                  <div class="row p-4">
                    <div
                      class="col-12 pl-4 pr-4"
                      style="background-color: #66c7eb"
                    >
                      <div class="row pl-4 pr-4 pt-2 pb-4">
                        <carousel
                          :navigation-enabled="true"
                          :per-page="1"
                          :mouse-drag="false"
                          :adjustable-height="true"
                          :pagination-size="20"
                          :resistance-coef="40"
                          class="col-12 mt-4 shadow p-2 mb-2 bg-white rounded"
                        >
                          <slide
                            v-for="(q, index) in fastAtividadeQuestoes.questoes"
                            :key="q.id_questao"
                          >
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
                                <h5>
                                  Questão {{ index + 1 }} de
                                  {{ fastAtividadeQuestoes.questoes.length }}
                                </h5>
                              </div>
                              <div
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                class="col-sm-12 col-md-12 col-lg-6 mt-2 text-right"
                              >
                                <button
                                  v-if="!q.questao_concurseiro"
                                  class="btn btn-sm btn-danger pt-0 pb-0"
                                  @click="exibeModalExcluirQuestaoQuiz(q)"
                                >
                                  <small>Excluir do QUIZ</small>
                                </button>
                                <button
                                  v-else
                                  class="btn btn-sm btn-danger pt-0 pb-0"
                                  @click="migrarQuestoesFastEAD(fastAtividadeQuestoes.questoes)"
                                >
                                  <small>Migrar questões para FastEAD</small>
                                </button>
                              </div>
                              <div class="col-12 mt-2">
                                <div v-html="q.pergunta" />
                              </div>
                              <div
                                v-if="q.alternativas.length"
                                class="col-12 p-4"
                              >
                                <div
                                  v-for="(a, index2) in q.alternativas"
                                  :key="a.id_alternativa"
                                  class="row pl-2 pr-2"
                                >
                                  <div :class="'col-12 alt-correta-' + a.correta">
                                    <div class="row">
                                      <div class="col-6 mt-2">
                                        <h6>Alternativa #{{ index2 + 1 }}</h6>
                                      </div>
                                      <div class="col-12">
                                        <div v-html="a.alternativa" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </slide>
                        </carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fast_atividade.tipo_atividade == 'PS'"
                class="row"
              >
                <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                  <h4 class="aluno_font_color">
                    Pesquisas ({{ fastAtividadePesquisa.length }})
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                  <button
                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                    class="btn btn-primary btn-sm mt-2 ml-2"
                    @click.prevent="showModal('modalCadastrarPesquisa')"
                  >
                    <small>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                      />
                      &nbsp;Adicionar pesquisa
                    </small>
                  </button>
                </div>
                <div
                  v-if="fastAtividadePesquisa.length"
                  class="col-12"
                >
                  <div class="row p-4">
                    <div
                      class="col-12 pl-4 pr-4"
                      style="background-color: #66c7eb"
                    >
                      <div class="row pl-4 pr-4 pt-2 pb-4">
                        <carousel
                          :navigation-enabled="true"
                          :per-page="1"
                          :mouse-drag="false"
                          :adjustable-height="true"
                          :pagination-size="20"
                          :resistance-coef="40"
                          class="col-12 mt-4 shadow p-2 mb-2 bg-white rounded"
                        >
                          <slide
                            v-for="(q, index) in fastAtividadePesquisa"
                            :key="q.id_pergunta"
                          >
                            <div class="row p-2">
                              <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
                                <h5>
                                  Pesquisa {{ index + 1 }} de
                                  {{ fastAtividadePesquisa.length }}
                                </h5>
                              </div>
                              <div
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                class="col-sm-12 col-md-12 col-lg-6 mt-2 text-right"
                              >
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-danger ml-2 mr-2"
                                  @click.prevent="exibeModalExcluirPesquisa(q)"
                                >
                                  <small>Excluir</small>
                                </button>
                              </div>
                            </div>

                            <div class="col-12 mt-2">
                              <quill-editor
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                v-model="q.html"
                                :options="editorOption"
                              />
                              <quill-editor
                                v-else
                                v-model="q.html"
                                :options="editorOption"
                                :disabled="true"
                              />
                            </div>
                            <div
                              v-if="q.alternativas && q.tipo_pesquisa == 'O'"
                              class="col-12 p-4"
                            >
                              <div
                                v-if="q.alternativas.length"
                                class="row"
                              >
                                <div
                                  v-for="(a, index2) in q.alternativas"
                                  :key="a.id_resposta"
                                  class="col-sm-12 col-md-12 col-lg-6 pl-2 pr-2 mb-4"
                                >
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h6>Alternativa #{{ index2 + 1 }}</h6>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <quill-editor
                                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                        v-model="a.html_resposta"
                                        :options="editorOption"
                                      />
                                      <quill-editor
                                        v-else
                                        v-model="a.html_resposta"
                                        :options="editorOption"
                                        :disabled="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                              class="col-12 text-center"
                            >
                              <button
                                v-if="q.tipo_pesquisa == 'O'"
                                href="#"
                                type="button"
                                class="btn btn-success ml-2 mr-2"
                                @click.prevent="adicionaAlternativaPesquisaEdicao(q.id_pergunta)"
                              >
                                <small>+ Alternativa</small>
                              </button>
                              <div
                                v-else
                                class="row mt-2 mb-4"
                              >
                                <div
                                  v-if="q.alternativas.length"
                                  class="col-12 text-center"
                                >
                                  <star-rating
                                    v-model="q.alternativas[0].estrelas"
                                    :show-rating="false"
                                    :max-rating="10"
                                    @rating-selected="atualizaEstrelaPesquisa(q)"
                                  />
                                </div>
                              </div>

                              <button
                                v-if="q.tipo_pesquisa == 'E'"
                                href="#"
                                type="button"
                                class="btn btn-primary ml-2 mr-2"
                                @click.prevent="atualizaEstrelaPesquisa(q)"
                              >
                                Salvar alterações
                              </button>
                              <button
                                v-else
                                href="#"
                                type="button"
                                class="btn btn-primary ml-2 mr-2"
                                @click.prevent="atualizaPerguntaPesquisa(q)"
                              >
                                Salvar alterações
                              </button>
                            </div>
                            <div
                              v-else
                              class="col-12 text-center"
                            >
                              <div
                                v-if="q.tipo_pesquisa == 'E'"
                                class="row mt-2 mb-4"
                              >
                                <div
                                  v-if="q.alternativas.length"
                                  class="col-12 text-center"
                                >
                                  <star-rating
                                    v-model="q.alternativas[0].estrelas"
                                    :show-rating="false"
                                    :max-rating="10"
                                  />
                                </div>
                              </div>
                            </div>
                          </slide>
                        </carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fast_atividade.tipo_atividade == 'AV'"
                class="row"
              >
                <tabs
                  class="pl-3 pr-3"
                  :options="{ useUrlFragment: false }"
                >
                  <tab
                    prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                    name="Lista de avaliações"
                  >
                    <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                      <h4 class="aluno_font_color">
                        Lista de avaliações do curso
                      </h4>
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr class="bg-dark text-white">
                            <th
                              style="width: 50px"
                              scope="col"
                            />
                            <th scope="col">
                              Aula
                            </th>
                            <th scope="col">
                              Atividade
                            </th>
                            <th scope="col">
                              Tentativas
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Tempo limite
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Peso
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Ações
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastAvaliacoesCurso.length">
                          <tr
                            v-for="(avaliacao, index) in fastAvaliacoesCurso"
                            :key="avaliacao.id_atividade"
                          >
                            <td class="text-center">
                              {{ index + 1 }}
                            </td>
                            <td>
                              {{ avaliacao.sequencia_aula }}º aula -
                              {{ avaliacao.titulo_aula }}
                            </td>
                            <td>
                              {{ avaliacao.sequencia_atividade }}º atividade -
                              {{ avaliacao.titulo_atividade }}
                            </td>
                            <td class="text-center">
                              {{ avaliacao.tentativas_maxima }}
                            </td>
                            <td class="text-center">
                              {{ avaliacao.tempo_limite }}
                            </td>
                            <td class="text-center">
                              {{ avaliacao.nota_prova }}
                            </td>
                            <td class="text-center">
                              <a
                                v-if="fastAvaliacaoAtual.id_atividade != avaliacao.id_atividade"
                                class="btn btn-primary"
                                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + $route.params.id_curso + '/aulas/' + avaliacao.id_aula + '/atividade/' + avaliacao.id_atividade"
                              >Acessar</a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td
                              colspan="7"
                              class="text-right"
                            >
                              <h6>
                                Peso total das avaliações:
                                {{ calculaPesoTotalAvaliacoes() }}
                              </h6>
                              <h6 v-if="fastAvaliacoesCurso.length">
                                Peso total do curso:
                                {{ fastAvaliacoesCurso[0].nota_maxima }}
                              </h6>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </tab>
                  <tab
                    prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                    name="Configurações da avaliação"
                  >
                    <div
                      v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                      class="col-sm-12 col-md-12 col-lg-6 text-center"
                    >
                      <h4 class="aluno_font_color">
                        Configurações da avaliação
                      </h4>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <label class="h6 mt-4">Tentativas máximas</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <input
                                v-model="fast_atividade.tentativas_maxima"
                                type="number"
                                class="form-control text-center"
                                @blur="confirmaAlteracoesAtividades()"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <label class="h6 mt-4">Tempo limite</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <input
                                v-model="fast_atividade.tempo_limite"
                                type="time"
                                class="form-control text-center"
                                @blur="confirmaAlteracoesAtividades()"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab>
                  <tab
                    prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                    name="Configurações do curso"
                  >
                    <div
                      v-if="fastAvaliacoesCurso.length"
                      class="col-sm-12 col-md-12 col-lg-6 text-center"
                    >
                      <h4 class="aluno_font_color">
                        Configurações do curso
                      </h4>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <label class="h6 mt-4">Nota média do curso</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <input
                                v-model="fastAvaliacoesCurso[0].nota_media"
                                type="number"
                                class="form-control text-center"
                                @blur="atualizaNotasCurso()"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <label class="h6 mt-4">Nota máxima do curso</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <input
                                v-model="fastAvaliacoesCurso[0].nota_maxima"
                                type="number"
                                class="form-control text-center"
                                @blur="atualizaNotasCurso()"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab>
                </tabs>

                <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                  <h4 class="aluno_font_color">
                    Questões da Avaliação
                    {{ fastAvaliacaoAtual.sequencia_aula }}
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                  <a
                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                    class="btn btn-primary btn-sm mt-2 ml-2"
                    :href="'/plataforma/' + $route.params.id_plataforma + '/admin/provas?atividade=' + fast_atividade.id_atividade + '&tipo=avaliacao#questões'"
                  >
                    <small>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                      />
                      &nbsp;Gerenciar questões
                    </small>
                  </a>
                </div>
                <div class="col-12 mt-2 pl-4 pr-4">
                  <div
                    class="progress"
                    style="height: 50px"
                  >
                    <div
                      v-for="avaliacao in fastAvaliacoesCurso"
                      :key="avaliacao.id_atividade"
                      :class="'progress-bar progress-bar-striped progress-bar-animated bg-' + avaliacao.cor"
                      role="progressbar"
                      :aria-valuenow="{ width: avaliacao.porcentagem }"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: avaliacao.porcentagem + '%' }"
                    >
                      <h6 class="m-0">
                        Avaliação {{ avaliacao.sequencia_aula }}
                      </h6>
                      <h6 class="m-0">
                        Peso:
                        <span>{{ avaliacao.nota_prova }}</span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div
                  v-if="fastAtividadeQuestoes.questoes.length"
                  class="col-12"
                >
                  <div class="row pl-4 pr-4 pb-4">
                    <div class="col-12">
                      <div class="row pb-4">
                        <carousel
                          :navigation-enabled="true"
                          :per-page="1"
                          :mouse-drag="false"
                          :adjustable-height="true"
                          :pagination-size="20"
                          :resistance-coef="40"
                          class="col-12 shadow-lg p-2 mb-2 bg-white rounded"
                        >
                          <slide
                            v-for="(q, index) in fastAtividadeQuestoes.questoes"
                            :key="q.id_questao"
                          >
                            <div class="row p-4">
                              <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                                <h5>
                                  Questão #{{q.id_questao}} - {{ index + 1 }} de
                                  {{ fastAtividadeQuestoes.questoes.length }}
                                </h5>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-9 text-right">
                                    <h5>Peso da questão</h5>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-3 pl-0">
                                    <input
                                      v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                      v-model="q.questao_peso"
                                      type="number"
                                      step=".01"
                                      class="form-control text-center"
                                      @change="atualizaPesoQuestaoAvaliacao(q)"
                                    >
                                    <input
                                      v-else
                                      type="number"
                                      step=".01"
                                      class="form-control text-center"
                                      :value="q.questao_peso"
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                class="col-sm-12 col-md-12 col-lg-4 mt-2 text-right"
                              >
                                <button
                                  class="btn btn-sm btn-danger"
                                  @click="exibeModalExcluirQuestaoAvaliacao(q)"
                                >
                                  <small class="pt-0 pb-0">Excluir da avaliação</small>
                                </button>
                              </div>

                              <div class="col-12 mt-4">
                                <div v-html="q.pergunta" />
                              </div>
                              <div
                                v-if="q.alternativas.length"
                                class="col-12 p-4"
                              >
                                <div
                                  v-for="(a) in q.alternativas"
                                  :key="a.id_alternativa"
                                  class="row pl-2 pr-2"
                                >
                                  <div :class="'col-12 alt-correta-' + a.correta">
                                    <div class="row">
                                      <div class="col-6 mt-2">
                                        <h6>Alternativa #{{ a.id_alternativa }}</h6>
                                      </div>
                                      <div class="col-12">
                                        <div v-html="a.alternativa" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </slide>
                        </carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fast_atividade.tipo_atividade == 'LV'"
                class="row"
              >
                <div class="col-12 mt-4">
                  <h4 class="aluno_font_color">
                    Configurações da live
                    <span
                      v-if="!fast_atividade.referencia_atividade"
                      class="text-danger"
                    >(Não agendada)</span>
                  </h4>
                </div>
                <div class="col-12 mt-4">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-3">
                      <label class="aluno_font_color">Título da live</label>
                      <input
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        v-model="fastLive.titulo_live"
                        type="text"
                        class="form-control"
                      >
                      <input
                        v-else
                        type="text"
                        class="form-control"
                        :value="fastLive.titulo_live"
                        readonly
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <label class="aluno_font_color">Detalhes da live</label>
                      <textarea
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        v-model="fastLive.detalhes"
                        class="form-control"
                      />
                      <textarea
                        v-else
                        v-model="fastLive.detalhes"
                        class="form-control"
                        readonly
                      />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3">
                      <label class="aluno_font_color">Tipo</label>
                      <select
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        v-model="fastLive.tipo"
                        class="form-control"
                      >
                        <option value="J">
                          FastEAD (Nova)
                        </option>
                        <option value="Z">
                          Zoom
                        </option>
                        <option value="M">
                          Google Meet
                        </option>
                        <option value="A">
                          Telemedicina (tarifada)
                        </option>
                      </select>
                      <select
                        v-else
                        v-model="fastLive.tipo"
                        class="form-control"
                        style="
                          background: #eee;
                          pointer-events: none;
                          touch-action: none;
                        "
                      >
                        <option value="J">
                          FastEAD (Nova)
                        </option>
                        <option value="Z">
                          Zoom
                        </option>
                        <option value="M">
                          Google Meet
                        </option>
                        <option value="A">
                          Telemedicina (tarifada)
                        </option>
                      </select>
                    </div>
                    <div
                      v-if="fastLive.tipo == 'M'"
                      class="col-12"
                    >
                      <label for>URL do Google Meet</label>
                      <input
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        v-model="fastLive.API_TOKEN"
                        type="text"
                        class="form-control"
                        placeholder="Gere a URL do Google Meet e insira aqui"
                      >
                      <input
                        type="text"
                        :value="fastLive.API_TOKEN"
                        class="form-control"
                        readonly
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                      <label class="aluno_font_color">
                        Data da live
                        <span v-if="fastLive.data_live">({{ formataDataLive(fastLive.data_live) }})</span>
                      </label>
                      <input
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        v-model="fastLive.data_live"
                        type="date"
                        class="form-control"
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                      <label class="aluno_font_color">Hora da live</label>
                      <input
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        v-model="fastLive.hora_live"
                        type="time"
                        class="form-control"
                      >
                      <input
                        v-else
                        :value="fastLive.hora_live"
                        type="time"
                        class="form-control"
                        readonly
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                      <label class="aluno_font_color">Duração da live</label>
                      <input
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        v-model="fastLive.duracao_live"
                        type="time"
                        class="form-control"
                      >
                      <input
                        v-else
                        :value="fastLive.duracao_live"
                        type="time"
                        class="form-control"
                        readonly
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                      <label class="aluno_font_color">Acesso</label>
                      <select
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        v-model="fastLive.acesso"
                        class="form-control"
                      >
                        <option value="R">
                          Restrito
                        </option>
                      </select>
                      <strong v-else> - Restrito</strong>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                      <label class="aluno_font_color">Participantes ({{ fastLive.participantes.length }})</label>
                      <div class="row">
                        <div
                          v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                          class="col-sm-12 col-md-12 col-lg-4 pb-2"
                        >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Filtrar por nome"
                            @keyup="filtraUsuario"
                          >
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                          <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                              <tr>
                                <th scope="col">
                                  Nome do usuário
                                </th>
                                <th scope="col">
                                  Email
                                </th>
                                <th
                                  scope="col"
                                  class="text-center"
                                >
                                  Ativo
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Ações
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="pageOfUsuarios.length">
                              <tr
                                v-for="u in pageOfUsuarios"
                                :key="u.email"
                              >
                                <td>{{ u.nome_usuario }}</td>
                                <td>{{ u.email }}</td>
                                <td class="text-center">
                                  <span class="text-success">{{ u.ativo == "S" ? "Ativo" : "" }}</span>
                                  <span class="text-warning">{{ u.ativo != "S" ? "Não ativo" : "" }}</span>
                                </td>
                                <td
                                  v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                                  class="text-center"
                                >
                                  <button
                                    v-if="u.ativo == 'S' && !verificaUsuarioIncluido(u.id_usuario)"
                                    class="btn btn-sm btn-success btn-block"
                                    title="Inserir participante"
                                    @click="adicionaParticipantesLive(u)"
                                  >
                                    <small> <b-icon-person-plus-fill /> Adicionar </small>
                                  </button>
                                  <button
                                    v-else-if="u.ativo == 'S' && verificaUsuarioIncluido(u.id_usuario)"
                                    class="btn btn-sm btn-danger btn-block"
                                    title="Remover participante"
                                    @click="removeParticipantesLive(u.id_usuario)"
                                  >
                                    <small> <b-icon-person-dash-fill /> Remover </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  class="text-center"
                                  colspan="4"
                                >
                                  Nenhum usuário disponível
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center">
                          <Pagination
                            :page-size="5"
                            :items="usuariosFiltro"
                            @changePage="pageOfUsuarios = $event"
                          />
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                          <label class="aluno_font_color">Tutor da live</label>
                          <select
                            v-if="fastLive.participantes.length"
                            v-model="fastLive.id_usuario_tutor"
                            class="form-control"
                          >
                            <option
                              v-for="participante in fastLive.participantes"
                              :key="participante.id_usuario"
                              :value="participante.id_usuario"
                              :checked="participante.id_usuario == fastLive.id_usuario_tutor ? true : false"
                            >
                              {{ participante.nome_usuario }}
                            </option>
                          </select>
                          <select
                            v-else
                            class="form-control"
                            style="
                              background: #eee;
                              pointer-events: none;
                              touch-action: none;
                            "
                          >
                            <option>Escolha um participante</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-4">
                      <div
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_aula.id_plataforma_origem || fast_curso.filhas_alteram_aula"
                        class="form-group mb-0"
                      >
                        <button
                          v-if="!fast_atividade.referencia_atividade"
                          href="#"
                          type="button"
                          class="btn btn-primary"
                          @click="criarLive()"
                        >
                          Agendar live
                        </button>
                        <button
                          v-else
                          href="#"
                          type="button"
                          class="btn btn-primary"
                          @click="editarLive()"
                        >
                          Alterar live
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fast_atividade.tipo_atividade == 'UP'"
                class="row"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <strong class="aluno_font_color">Lista usuários</strong>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-right" />
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm table-striped">
                        <thead class="thead-dark">
                          <tr>
                            <th />
                            <th>
                              <small class="font-weight-bold">Aluno</small>
                            </th>

                            <th class="text-center">
                              <small class="font-weight-bold">Envios</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Corrigidos</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastUploads.length">
                          <tr
                            v-for="(u, index) in fastUploads"
                            :key="u.id_usuario"
                          >
                            <td>
                              <small>{{ index + 1 }}</small>
                            </td>
                            <td>
                              <small>{{ u.nome_usuario }}</small>
                            </td>
                            <td class="text-center">
                              <small class="btn btn-sm btn-info pl-3 pr-3">{{ u.envios.length }}</small>
                            </td>
                            <td class="text-center">
                              <div v-if="u.envios.length == retornaCorrecoes(u.envios)">
                                <small
                                  class="btn btn-sm btn-success"
                                  @click="exibeModalEnvios(u.envios)"
                                >100%</small>
                              </div>
                              <div v-else-if="retornaCorrecoes(u.envios) == 0">
                                <small
                                  class="btn btn-sm btn-danger"
                                  @click="exibeModalEnvios(u.envios)"
                                >Nenhum</small>
                              </div>
                              <div v-else>
                                <small
                                  class="btn btn-sm btn-warning pl-3 pr-3"
                                  @click="exibeModalEnvios(u.envios)"
                                >{{ retornaCorrecoes(u.envios) + "/" + u.envios.length }}</small>
                              </div>
                            </td>

                            <td class="text-center">
                              <button
                                class="btn btn-sm btn-success"
                                @click="exibeModalEnvios(u.envios)"
                              >
                                Visualizar
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Nenhum envio
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row p-4">
                <div class="col-12 shadow p-4 mb-4 bg-white rounded">
                  <h4>Estatísticas dos alunos</h4>
                  <div class="row mt-4">
                    <div
                      v-if="fastAulaEstatisticaCarregou"
                      class="col-sm-12 col-md-12 col-lg-12 table-responsive"
                    >
                      <div class="row mb-2">
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Filtrar pelo nome do aluno"
                            @keyup="filtraAlunos"
                          >
                        </div>
                      </div>
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr class="bg-dark text-white">
                            <th
                              style="width: 50px"
                              class="text-center"
                              scope="col"
                            >
                              Id
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Nome
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Realizou
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Limite tentativa
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Data de início
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Data de finalização
                            </th>
                            <th
                              class="text-center"
                              scope="col"
                            >
                              Ações
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastAulaEstatisticasFiltro.length">
                          <tr
                            v-for="u in pageOfEstatisticas"
                            :key="u.id_usuario"
                          >
                            <td>#{{ u.id_usuario }}</td>
                            <td>
                              {{ u.nome_usuario }}
                            </td>                            
                            <td class="text-center">
                              <span v-if="u.count">
                                <span v-if="u.count == 1">Uma vez</span>
                                <span v-else>{{ u.count }} vezes</span>
                              </span>                              
                            </td>
                            <td class="text-center">
                              {{ u.tentativas_maxima }}
                            </td>
                            <td class="text-center">
                              <span v-if="u.data_inicio">
                                {{ new Date(u.data_inicio).toLocaleDateString() + " " + u.data_inicio.split("T")[1].split(".")[0] }}
                              </span>
                            </td>
                            <td class="text-center">
                              <span v-if="u.data_fim">
                                {{ new Date(u.data_fim).toLocaleDateString() + " " + u.data_fim.split("T")[1].split(".")[0] }}
                              </span>
                            </td>
                            <td class="text-center">
                              <span v-if="fast_atividade.tipo_atividade == 'AV' && u.data_inicio">
                                <span
                                  class="btn btn-sm btn-primary m-1 pt-0 pb-0 d-block"
                                  @click="exibeModalDesempenhoAluno(fast_atividade.tipo_atividade, u)"
                                >
                                  <small>Ver desempenho</small>
                                </span>
                                <span
                                  class="btn btn-sm btn-primary m-1 pt-0 pb-0 d-block"
                                  @click="getRespostasProva(u.id_usuario_atividade, u.id_usuario)"
                                >
                                  <small>Ver respostas</small>
                                </span>
                                <span
                                  class="btn btn-sm btn-primary m-1 pt-0 pb-0 d-block"
                                  @click="exibeModalAlterarTentativa(u)"
                                >
                                  <small>Alterar tentativas</small>
                                </span>
                                <a
                                  v-if="u.path_gabarito"
                                  :href="u.path_gabarito"
                                  target="_blank"
                                  class="btn btn-sm btn-success m-1 pt-0 pb-0 d-block"
                                >
                                  <small>Gabarito assinado</small>
                                </a>
                                <span
                                  v-else
                                  class="btn btn-sm btn-secondary disabled m-1 pt-0 pb-0 d-block"
                                >
                                  <small>Gabarito assinado</small>
                                </span>
                              </span>
                              <span v-else-if="fast_atividade.tipo_atividade == 'PS' && u.data_inicio">
                                <small
                                  class="btn btn-sm btn-primary"
                                  @click="exibeModalDesempenhoAluno(fast_atividade.tipo_atividade, u)"
                                >
                                  Ver respostas
                                </small>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="7"
                              class="text-center"
                            >
                              <h6>Nenhum aluno iniciou essa atividade</h6>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastAulaEstatisticasFiltro"
                          @changePage="pageOfEstatisticas = $event"
                        />
                      </div>
                    </div>
                    <div
                      v-else
                      class="col-sm-12 col-md-12 col-lg-12 text-center"
                    >
                      <button
                        class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                        @click.prevent="getFastAulaAtividadeEstatisticas()"
                      >
                        <small>Exibir estatísticas</small>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + $route.params.id_curso + '/aulas'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + $route.params.id_curso + '/aulas')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCadastrarVideoFile"
      :scrollable="true"
      height="100%"
      width="100%"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Cadastrar novo video</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarVideoFile')"
          >Fechar</a>
        </div>
        <div
          v-if="optionsVideo.target"
          class="col-12 pt-4"
        >
          <uploader
            :file-status-text="statusText"
            :options="optionsVideo"
            class="uploader-example"
            @file-success="vimeoSuccess"
            @file-added="fileValidation"
          >
            <uploader-unsupport />
            <uploader-drop>
              <p>Arraste um arquivo MP4 aqui ou</p>
              <uploader-btn :attrs="restrictVideo">
                Clique aqui para selecionar
              </uploader-btn>
            </uploader-drop>
            <uploader-list />
          </uploader>
        </div>
        <div
          v-if="!optionsVideo.target"
          class="col-12 pr-4 mt-2 pb-0 pl-4"
        >
          <div class="row pr-4 pl-4">
            <div class="col-12 pr-4 pl-4">
              <h6>Você não pode enviar nenhum conteúdo que:</h6>
              <ul>
                <li>
                  <small>Infrinja os direitos autorais ou outros direitos de terceiros (por exemplo, marca registrada, direitos de privacidade etc.);</small>
                </li>
                <li>
                  <small>É sexualmente explícito (por exemplo, pornografia) ou propõe uma transação de natureza sexual;</small>
                </li>
                <li>
                  <small>É odioso, difamatório ou discriminatório ou incita o ódio contra qualquer indivíduo ou grupo;</small>
                </li>
                <li>
                  <small>Promove ou apoia grupos de terror ou ódio;</small>
                </li>
                <li>
                  <small>Explora menores;</small>
                </li>
                <li>
                  <small>Descreve atos ilegais ou violência extrema;</small>
                </li>
                <li>
                  <small>Fornece instruções sobre como montar dispositivos explosivos / incendiários ou armas de fogo caseiras / improvisadas;</small>
                </li>
                <li>
                  <small>Descreve crueldade animal ou violência extrema contra animais;</small>
                </li>
                <li>
                  <small>Promove esquemas comerciais fraudulentos ou duvidosos ou propõe uma transação ilegal;</small>
                </li>
                <li>
                  <small>Apresenta alegações falsas ou enganosas sobre a segurança da vacinação;</small>
                </li>
                <li>
                  <small>Transmite informações falsas ou enganosas relacionadas à saúde que têm um sério potencial de causar danos ao público;</small>
                </li>
                <li>
                  <small>Alega que tragédias em massa são trotes ou operações com bandeira falsa;</small>
                </li>
                <li>
                  <small>Descreve ou incentiva a automutilação; ou Viole qualquer lei aplicável.</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4 text-center">
          <button
            v-if="!optionsVideo.target"
            class="btn btn-primary"
            @click="criaVideoVimeo()"
          >
            Aceito os termos
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="modalCadastrarVideoVimeo"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Cadastrar novo video</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarVideoVimeo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div class="col-12">
              <h6>Insira a URL do video do Vimeo:</h6>
            </div>
            <div class="col-12">
              <input
                v-model="nova_referencia_atividade"
                class="form-control"
                type="text"
                placeholder="Ex: https://vimeo.com/309343413/"
                @keyup="validaVideoVimeo()"
              >
            </div>
            <div
              v-if="nova_referencia_atividade"
              class="col-12 mt-4 text-center"
            >
              <h6>Preview:</h6>
              <iframe
                :src="nova_referencia_atividade"
                frameborder="0"
                height="200px"
              />
            </div>
            <div
              v-if="nova_referencia_atividade"
              class="col-12 mt-2"
            >
              <div class="form-group mb-0 text-center">
                <small>Verifique se o seu video aparece no preview e clique em confirmar.</small>
              </div>
              <div class="form-group mb-0 text-center">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="confirmaAlteracoesAtividades()"
                >
                  <small>Confirmar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCadastrarVideoYoutube"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Cadastrar novo video</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarVideoYoutube')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div class="col-12">
              <h6>Insira a URL do video do Youtube:</h6>
            </div>
            <div class="col-12">
              <input
                v-model="nova_referencia_atividade"
                class="form-control"
                type="text"
                placeholder="Ex: https://www.youtube.com/watch?v=xSNaIWXJm0Y"
                @keyup="validaVideoYoutube()"
              >
            </div>
            <div
              v-if="nova_referencia_atividade"
              class="col-12 mt-4 text-center"
            >
              <h6>Preview:</h6>
              <iframe
                :src="nova_referencia_atividade"
                frameborder="0"
                height="200px"
              />
            </div>
            <div
              v-if="nova_referencia_atividade"
              class="col-12 mt-2"
            >
              <div class="form-group mb-0 text-center">
                <small>Verifique se o seu video aparece no preview e clique em confirmar.</small>
              </div>
              <div class="form-group mb-0 text-center">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="confirmaAlteracoesAtividades()"
                >
                  <small>Confirmar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCadastrarPesquisa"
      :scrollable="true"
      width="80%"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row text-left p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Cadastrar pesquisa</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarPesquisa')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-12">
                  <h6>Insira sua questão:</h6>
                </div>
                <div class="col-12">
                  <quill-editor
                    v-model="novaPesquisa.html"
                    :options="editorOption"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <h6>Tipo de pesquisa:</h6>
              <select
                v-model="novaPesquisa.tipo_pesquisa"
                class="form-control"
              >
                <option value>
                  -- Selecione o tipo --
                </option>
                <option value="E">
                  Avaliação com estrelas
                </option>
                <option value="O">
                  Múltipla escolha
                </option>
              </select>
            </div>
            <div
              v-if="novaPesquisa.tipo_pesquisa == 'O'"
              class="col-12"
            >
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="row mt-4">
                    <div class="col-6">
                      <h6>Alternativa a)</h6>
                    </div>
                    <div class="col-12">
                      <quill-editor
                        v-model="novaPesquisa.alternativas[0].html_resposta"
                        :options="editorOption"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="row mt-4">
                    <div class="col-6">
                      <h6>Alternativa b)</h6>
                    </div>
                    <div class="col-12">
                      <quill-editor
                        v-model="novaPesquisa.alternativas[1].html_resposta"
                        :options="editorOption"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="row mt-4">
                    <div class="col-6">
                      <h6>Alternativa c)</h6>
                    </div>
                    <div class="col-12">
                      <quill-editor
                        v-model="novaPesquisa.alternativas[2].html_resposta"
                        :options="editorOption"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="row mt-4">
                    <div class="col-6">
                      <h6>Alternativa d)</h6>
                    </div>

                    <div class="col-12">
                      <quill-editor
                        v-model="novaPesquisa.alternativas[3].html_resposta"
                        :options="editorOption"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="row mt-4">
                    <div class="col-6">
                      <h6>Alternativa e)</h6>
                    </div>

                    <div class="col-12">
                      <quill-editor
                        v-model="novaPesquisa.alternativas[4].html_resposta"
                        :options="editorOption"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="novaPesquisa.tipo_pesquisa == 'E'"
              class="col-12 mt-4 text-center"
            >
              <h6>Selecione o número de estrelas:</h6>
              <star-rating
                v-model="novaPesquisa.alternativas[0].estrelas"
                :show-rating="false"
                :max-rating="10"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <button
                href="#"
                type="button"
                class="btn btn-primary"
                @click.prevent="criaFastPesquisa()"
              >
                Criar pesquisa
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirPesquisa"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir pesquisa?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirPesquisa')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-danger btn-sm mt-2 ml-2"
            @click.prevent="confirmaExcluirPesquisa()"
          >
            <i
              class="fa fa-ban"
              aria-hidden="true"
            /> &nbsp;Confirmar
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirQuestaoAvaliacao"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir questão da avaliação?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirQuestaoAvaliacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-danger btn-sm mt-2 ml-2"
            @click.prevent="confirmaExcluirQuestaoAvaliacao()"
          >
            <i
              class="fa fa-ban"
              aria-hidden="true"
            /> &nbsp;Confirmar
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirQuestaoQuiz"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir questão do QUIZ?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirQuestaoQuiz')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-danger btn-sm mt-2 ml-2"
            @click.prevent="confirmaExcluirQuestaoQuiz()"
          >
            <i
              class="fa fa-ban"
              aria-hidden="true"
            /> &nbsp;Confirmar
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="modalCriarAtividade"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Criar atividade</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('RF')"
                  >
                    <div class="text-center">
                      <b-icon-file-earmark-text font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Arquivo</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('AD')"
                  >
                    <div class="text-center">
                      <b-icon-volume-up font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Audio</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('VA')"
                  >
                    <div class="text-center">
                      <b-icon-collection-play-fill font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Video</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('QZ')"
                  >
                    <div class="text-center">
                      <b-icon-ui-checks font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Quiz</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('PS')"
                  >
                    <div class="text-center">
                      <b-icon-clipboard-data font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Pesquisa</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4 d-none">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('AV')"
                  >
                    <div class="text-center">
                      <b-icon-stopwatch font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Avaliação objetiva</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('UP')"
                  >
                    <div class="text-center">
                      <b-icon-cloud-upload font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Upload</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4 d-none">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('LV')"
                  >
                    <div class="text-center">
                      <b-icon-camera-video font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Live</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCriarAtividadeTipo"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>
            Atividade do tipo
            {{ formataTipoAtividade(novaAtividade.tipo_atividade) }}
          </h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAtividadeTipo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input
                  v-model="novaAtividade.titulo_atividade"
                  class="form-control"
                  type="text"
                  placeholder="Titulo da atividade"
                >
                <textarea
                  v-model="novaAtividade.detalhe_atividade"
                  class="form-control mt-2"
                  placeholder="Descrição do atividade"
                />
              </div>
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="validaCriarAtividade()"
                >
                  Criar atividade
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirAtividade"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir atividade?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirAtividade()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEnvios"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4 v-if="fastUploadsAlunoEnvios.length">
            {{ fastUploadsAlunoEnvios[0].nome_usuario }}
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEnvios')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th />
                    <th>
                      <small class="font-weight-bold">Data do envio</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Corrigido</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(u, index) in fastUploadsAlunoEnvios"
                    :key="u.id_usuario_upload"
                  >
                    <td>
                      <small>{{ index + 1 }}</small>
                    </td>
                    <td>
                      <small>{{ new Date(u.data_hora).toLocaleString().split(" ")[0] }} {{ u.data_hora.split("T")[1] }}</small>
                    </td>
                    <td class="text-center">
                      <i
                        v-if="u.corrigido == 'S'"
                        class="far fa-check-circle fa-2x text-success"
                      />
                      <span
                        v-else
                        class="text-warning"
                      >Pendente</span>
                    </td>
                    <td class="text-center">
                      <button
                        class="btn btn-sm btn-success"
                        @click="exibeModalUploadEnvioAluno(u)"
                      >
                        <small>Ver envio</small>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEnvio"
      :shift-y="0.1"
      :width="modalWidth"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4 v-if="fastUploadsAlunoEnvio.id_usuario_upload">
                {{ fastUploadsAlunoEnvio.nome_usuario }} - Enviado em
                {{ new Date(fastUploadsAlunoEnvio.data_hora).toLocaleString().split(" ")[0] }}
                {{ fastUploadsAlunoEnvio.data_hora.split("T")[1] }}
              </h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalEnvio')"
              >
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row pr-lg-4 pl-lg-4 pb-4">
            <div class="col-12 mt-4">
              <div
                v-if="fastUploadsAlunoEnvio.id_usuario_upload"
                class="row"
              >
                <div class="col-12">
                  <h6>Envio do aluno</h6>
                </div>
                <div
                  class="col-12"
                  v-html="fastUploadsAlunoEnvio.html_texto"
                />
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th />
                        <th>
                          <small class="font-weight-bold">Anexo</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="u in fastUploadsAlunoEnvio.anexos"
                        :key="u.id_usuario_upload_anexo"
                      >
                        <td>
                          <small>{{ u.sequencia }}</small>
                        </td>
                        <td>
                          <small>
                            <a
                              v-if="u.path"
                              :href="u.path"
                              target="_blank"
                              download
                            >Anexo_{{ u.sequencia }}.{{ u.path.split(".").pop() }}</a>
                            <span v-else>Nenhum anexo</span>
                          </small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  v-if="!fastUsuarioUploadProfessor.id_usuario_upload"
                  class="col-12"
                >
                  <div class="row">
                    <div class="col-12">
                      <h6>Insira aqui a sua correção</h6>
                    </div>
                    <div class="col-12">
                      <quill-editor
                        v-model="fastUsuarioUploadNovo.html_texto"
                        :options="editorOption"
                      />
                    </div>
                    <div class="col-12 mt-4">
                      <h6>Enviar anexos:</h6>
                    </div>
                    <div class="col-12">
                      <uploader
                        :file-status-text="statusText"
                        :options="optionsArquivoResposta"
                        class="uploader-example"
                        @file-success="fileSuccessResposta"
                        @file-added="fileValidation"
                      >
                        <uploader-unsupport />
                        <uploader-drop>
                          <p>Arraste um arquivo aqui ou</p>
                          <uploader-btn :attrs="restrictArquivoResposta">
                            Clique aqui para selecionar
                          </uploader-btn>
                        </uploader-drop>
                        <uploader-list />
                      </uploader>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="col-12"
                >
                  <div class="row">
                    <div class="col-12">
                      <h6>Correção</h6>
                    </div>
                    <div
                      class="col-12"
                      v-html="fastUsuarioUploadProfessor.html_texto"
                    />
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm table-striped">
                        <thead class="thead-dark">
                          <tr>
                            <th />
                            <th>
                              <small class="font-weight-bold">Anexo</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="u in fastUsuarioUploadProfessor.anexos"
                            :key="u.id_usuario_upload_anexo"
                          >
                            <td>
                              <small>{{ u.sequencia }}</small>
                            </td>
                            <td>
                              <small>
                                <a
                                  v-if="u.path"
                                  :href="u.path"
                                  target="_blank"
                                  download
                                >Anexo_{{ u.sequencia }}.{{ u.path.split(".").pop() }}</a>
                                <span v-else>Nenhum anexo</span>
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!fastUsuarioUploadProfessor.id_usuario_upload"
                class="row mt-4 text-center"
              >
                <div class="col-12">
                  <button
                    class="btn btn-primary"
                    @click="insereFastUsuarioUploadProfessor()"
                  >
                    Confirmar envio
                  </button>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <button
                    class="btn btn-danger"
                    @click="showModal('modalEnvios', 'modalEnvio')"
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalStatusMigracaoQuestoes"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Migrando questões</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalStatusMigracaoQuestoes')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-center">
              <h6 class="text-info">
                {{ fastStatusMigracaoQuestoes }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalDesempenhoProvaAluno"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Desempenho do aluno</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalDesempenhoProvaAluno')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mb-4">
              <div
                v-if="fastUsuarioDesempenho.image"
                :style="{
                  backgroundImage: 'url(' + ajustaLinkImageUser(fastUsuarioDesempenho.image_prof) + ')',
                  width: '60px',
                  height: '60px',
                  margin: 'auto',
                  marginBottom: '5px',
                  borderRadius: '50%',
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50%',
                }"
              />
              <h6>
                O aluno
                <span class="text-info">{{ fastUsuarioDesempenho.nome_usuario }}</span>
                atingiu a nota {{ fastNotaAvaliacao }} de {{ fastPesoAvaliacao }}.
              </h6>
              <h6>
                <span class="text-info">{{ fast_atividade.titulo_atividade }}</span>
              </h6>
              <h6 v-if="fastUsuarioDesempenho.data_inicio">
                Data de início:
                <span class="text-info">
                  {{ new Date(fastUsuarioDesempenho.data_inicio).toLocaleDateString() + " às " + fastUsuarioDesempenho.data_inicio.split("T")[1].split(".")[0] }}
                </span>
              </h6>
              <h6 v-if="fastUsuarioDesempenho.data_fim">
                Data de finalização:
                <span class="text-info">
                  {{ new Date(fastUsuarioDesempenho.data_fim).toLocaleDateString() + " às " + fastUsuarioDesempenho.data_fim.split("T")[1].split(".")[0] }}
                </span>
              </h6>
              <h6 v-if="fastUsuarioDesempenho.count">
                Realizou:
                <span
                  v-if="fastUsuarioDesempenho.count == 1"
                  class="text-info"
                >Uma vez</span>
                <span
                  v-else
                  class="text-info"
                >{{ fastUsuarioDesempenho.count }} vezes</span>
              </h6>
              <h6 v-if="fastUsuarioDesempenho.tentativas_maxima">
                Tentativas máximas:
                <span class="text-info">{{ fastUsuarioDesempenho.tentativas_maxima }}</span>
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <div class="d-lg-flex justify-content-center">
                <div class="d-flex justify-content-center">
                  <VueApexCharts
                    width="380"
                    :options="chartOptionsProvaPeso"
                    :series="chartDataProvaPeso"
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <VueApexCharts
                    width="380"
                    :options="chartOptions"
                    :series="chartDataProva"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalResultadoPesquisaAluno"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Resultado da pesquisa</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalResultadoPesquisaAluno')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mb-4">
              <div
                v-if="fastUsuarioDesempenho.image"
                :style="{
                  backgroundImage: 'url(' + ajustaLinkImageUser(fastUsuarioDesempenho.image_prof) + ')',
                  width: '60px',
                  height: '60px',
                  margin: 'auto',
                  marginBottom: '5px',
                  borderRadius: '50%',
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50%',
                }"
              />
              <h6>
                <span class="text-info">{{ fastUsuarioDesempenho.nome_usuario }}</span>
              </h6>
              <h6 v-if="fastUsuarioDesempenho.data_inicio">
                Data de início:
                <span class="text-info">
                  {{ new Date(fastUsuarioDesempenho.data_inicio).toLocaleDateString() + " às " + fastUsuarioDesempenho.data_inicio.split("T")[1].split(".")[0] }}
                </span>
              </h6>
              <h6 v-if="fastUsuarioDesempenho.data_fim">
                Data de finalização:
                <span class="text-info">
                  {{ new Date(fastUsuarioDesempenho.data_fim).toLocaleDateString() + " às " + fastUsuarioDesempenho.data_fim.split("T")[1].split(".")[0] }}
                </span>
              </h6>
            </div>
          </div>
          <div class="row">
            <div
              v-for="(p, index) in fastPesquisaResultado"
              :key="index"
              class="col-12 text-center"
            >
              <h6>Pesquisa {{ index + 1 }}</h6>
              <ul class="list-group mb-4">
                <li
                  class="list-group-item"
                  v-html="p.html"
                />
                <li
                  v-if="p.tipo_pesquisa == 'E'"
                  class="list-group-item"
                >
                  O aluno avaliou em
                  <span class="text-info">{{ p.estrelas_escolhida }}</span>
                  estrelas
                </li>
                <li
                  v-else
                  class="list-group-item"
                >
                  <div
                    v-for="a in p.alternativas"
                    :key="a.id_reposta"
                  >
                    <div v-if="a.opcao_escolhida">
                      <span
                        class="text-info"
                        v-html="a.html_resposta"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalRespostasAvaliacao"
      :width="modalWidthLg"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Respostas do aluno</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalRespostasAvaliacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row p-lg-4">
            <div class="col-12 pl-lg-4 pr-lg-4">
              <div class="row pl-2 pr-2 pb-lg-4">
                <carousel
                  :navigation-enabled="true"
                  :per-page="1"
                  :touch-drag="true"
                  :mouse-drag="true"
                  :adjustable-height="true"
                  :pagination-size="20"
                  :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded"
                >
                  <slide
                    v-for="(q, index) in fastRespostasAvaliacao"
                    :key="q.id_questao"
                  >
                    <div class="col-12 mt-2">
                      <h5>
                        Questão #{{q.id_questao}} - {{ index + 1 }} de
                        {{ fastRespostasAvaliacao.length }}
                      </h5>
                    </div>

                    <div class="col-12 mt-2 max-width-100vw">
                      <div v-html="q.pergunta" />
                    </div>
                    <div
                      v-if="q.alternativas.length"
                      class="col-12 p-2 max-width-100vw"
                    >
                      <div
                        v-for="(a) in q.alternativas"
                        :key="a.id_alternativa"
                        class="row pl-2 pr-2"
                      >
                        <div class="col-12">
                          <div
                            v-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'S'"
                            :class="'d-flex fast-aula-alternativa-escolhida-correta'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'N'"
                            :class="'d-flex fast-aula-alternativa-escolhida-incorreta'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else
                            :class="'d-flex fast-aula-alternativa'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    
    <modal
      name="modalAlterarTentativa"
      :width="modalWidthSm"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Alterar tentativa do aluno</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAlterarTentativa')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <h6
            v-if="fastUsuarioAtividadeTentativa.count"
            class="mb-4"
          >
            O aluno realizou essa atividade:
            <span
              v-if="fastUsuarioAtividadeTentativa.count == 1"
              class="text-info"
            >Uma vez</span>
            <span
              v-else
              class="text-info"
            >{{ fastUsuarioAtividadeTentativa.count }} vezes</span>
          </h6>
          <h6
            v-else
            class="mb-4"
          >
            O aluno nunca realizou essa atividade
          </h6>
          <div
            v-if="fastUsuarioAtividadeTentativa.tentativas_maxima"
            class="mb-4"
          >
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h6>Tentativas máximas:</h6>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2">
                <input
                  v-model="fastUsuarioAtividadeTentativa.tentativas_maxima"
                  class="form-control"
                  type="number"
                  min="1"
                >
              </div>
            </div>
          </div>
          <h6
            v-else
            class="mb-4"
          >
            Não existem tentativas para essa atividade
          </h6>
        </div>
        <div class="col-12">
          <button
            class="btn btn-primary btn-sm mt-2 ml-2"
            @click.prevent="confirmaAlterarTentativa()"
          >
            Confirmar alteração
          </button>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import vueStep from "vue-step";
import VueApexCharts from "vue-apexcharts"; //https://github.com/apexcharts/vue-apexcharts https://apexcharts.com/vue-chart-demos
import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import StarRating from "vue-star-rating";

import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaCursoAulaAtividade",
  components: {
    vueStep,
    Carousel,
    Slide,
    quillEditor,
    StarRating,
    Pagination,
    VueApexCharts,
  },
  mixins: [methods],
  data: function() {
    return {
      settings,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fast_curso: {},
      fast_aula: {},
      fast_atividade: {},
      fast_atividades: [],
      nova_referencia_atividade: "",
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Nova atividade
      novaAtividade: {
        id_curso: 0,
        id_aula: 0,
        incrementa_evolucao: true,
        publicada: false,
        titulo_atividade: "",
        detalhe_atividade: "...",
        tipo_atividade: "",
        referencia_atividade: "",
      },
      // Aulas
      aulasTotal: [],
      aulasFiltro: [],
      pageOfAulas: [],
      // Plugins steps
      nowStep: 0,
      stepList: [],
      // Upload video
      optionsVideo: {
        target: "",
        fileParameterName: "file_data",
        testChunks: false,
        chunkSize: 1024 * 1024 * 1024, // 1GB
        singleFile: true,
        method: "POST",
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictVideo: {
        accept: "video/mp4",
      },
      fastNovoVideo: "",
      fastVimeoVideoId: "",
      // Upload arquivo
      optionsArquivo: {
        target: settings.endApiFastEad + "api/fast_curso_aula_atividade/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          tipo_atividade: "RF",
          id_aula: this.$route.params.id_aula,
          id_atividade: this.$route.params.id_atividade,
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "odt", "doc", "docx", "ppt", "pptx", "gif", "jpg", "jpeg", "png", "rar"],
        },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept: "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/x-rar-compressed,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Upload audio
      optionsAudio: {
        target: settings.endApiFastEad + "api/fast_curso_aula_atividade/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          tipo_atividade: "AD",
          id_aula: this.$route.params.id_aula,
          id_atividade: this.$route.params.id_atividade,
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictAudio: {
        accept: "audio/mpeg",
      },
      // Quiz e Avaliação
      fastAtividadeQuestoes: {
        tipo_atividade: "",
        questoes: [],
      },
      excluirQuestaoQuiz: [],
      // Pesquisa de satisfação
      fastAtividadePesquisa: [],
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },
      // Nova pesquisa
      novaPesquisa: {
        id_pergunta: 0,
        tipo_pesquisa: "",
        html: "",
        alternativas: [
          {
            id_resposta: 0,
            estrelas: 0,
            html_resposta: "",
          },
          {
            id_resposta: 0,
            estrelas: 0,
            html_resposta: "",
          },
          {
            id_resposta: 0,
            estrelas: 0,
            html_resposta: "",
          },
          {
            id_resposta: 0,
            estrelas: 0,
            html_resposta: "",
          },
          {
            id_resposta: 0,
            estrelas: 0,
            html_resposta: "",
          },
        ],
      },
      // Excluir pesquisa
      excluirPesquisa: [],
      // Lista de avaliações do curso
      fastAvaliacoesCurso: [],
      fastAvaliacaoAtual: [],
      // Cores da barra de progresso
      cores: ["success", "info", "warning", "danger"],
      // Excluir questão da avaliação
      excluirQuestaoAvaliacao: [],
      // Live
      fastLive: {
        id_live: "",
        API_TOKEN: "",
        nr_maximo_sala: "",
        data_live: "",
        hora_live: "",
        id_usuario_tutor: "",
        id_usuario_moderador: "",
        detalhes: "",
        titulo_live: "",
        id_plataforma: 0,
        status: "",
        video_registro: "",
        duracao_live: "",
        acesso: "R",
        id_usuario_responsavel: "",
        tipo: "J",
        participantes: [],
      },
      // Lista de usuarios
      usuariosTotal: [],
      usuariosFiltro: [],
      pageOfUsuarios: [],
      // Parametros do usuarios
      fastUsuarioParametro: {
        id_usuario: "",
        image: "",
        url_zoom: "",
      },
      // Upload
      fastUploads: [],
      fastUploadsAlunoEnvios: [],
      fastUploadsAlunoEnvio: [],
      // Largura do modal
      modalWidth: "80%",
      fastUsuarioUploadNovo: {
        id_usuario_atividade: "",
        html_texto: "",
        paths: [],
      },
      optionsArquivoResposta: {
        target: settings.endApiFastEad + "api/fast_curso_aula_atividade/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: false,
        query: {
          tipo_atividade: "UP",
          id_aula: this.$route.params.id_aula,
          id_atividade: "",
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "html"],
        },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivoResposta: {
        accept: "application/pdf,text/html",
      },
      fastUsuarioUploadProfessor: {
        id_usuario_upload: "",
        data_hora: "",
        html_texto: "",
        anexos: [],
      },
      // Migração
      fastStatusMigracaoQuestoes: "",
      // Estatísticas
      fastAulaEstatisticasFiltro: [],
      fastAulaEstatisticasTotal: [],
      pageOfEstatisticas: [],
      fastAulaEstatisticaCarregou: false,
      // Desempenho do aluno
      fastNotaAvaliacao: "",
      fastPesoAvaliacao: "",
      fastUsuarioDesempenho: {
        count: "",
        data_inicio: "",
        data_fim: "",
        image: "",
        nome_usuario: "",
      },
      // Chart
      chartOptions: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Acertos", "Erros"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsProvaPeso: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Nota atingida", "Nota restante"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartDataQuiz: [0, 0],
      chartDataProva: [0, 0],
      chartDataProvaPeso: [0, 0],
      fastPesquisaResultado: [],
      fastRespostasAvaliacao: [],
      fastUsuarioAtividadeTentativa: {
        id_usuario_atividade: "",
        id_usuario: "",
        tentativas_maxima: "",
        count: ""
      }
    };
  },
  computed: {
    fastLiveNovaTipo() {
      return this.fastLive.tipo;
    },
  },
  watch: {
    fastLiveNovaTipo(value) {
      this.$toast.clear();
      if (value == "Z" && !this.fastUsuarioParametro.url_zoom) {
        this.$toast.open({
          message: "Clique aqui para configurar sua URL do Zoom",
          type: "error",
          duration: 60000,
          dismissible: true,
          position: "bottom-right",
          onClick: this.redirecionaPerfil,
        });
      }
    },
  },
  mounted: function() {
    if (this.$route.params.id_plataforma && this.$route.params.id_curso && this.$route.params.id_aula && this.$route.params.id_atividade) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getAulaAtividade();
          this.getFastAula();
          this.getFastCurso();
          /*
          if (this.$store.state.fastPermissoes.ativo == "S" && this.$store.state.fastPermissoes.administrador == "S") {
            this.getAulaAtividade();
            this.getFastAula();
            this.getFastCurso();
          } else if (this.$store.state.fastPermissoes.professor_only) {
            this.getAulaAtividade();
            this.getFastAula();
            this.getFastCursoProfessor();
            this.exibeToasty("Você está acessando com perfil de professor", "info");
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Você não possui permissões", "error");
          }*/
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastCurso() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_plataforma/lista_curso_admin?id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length > 0) {
          this.fast_curso = objCurso[0];
        }
        console.log("getFastCurso", this.fast_curso);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastCursoProfessor() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_plataforma/lista_curso_professor?id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length > 0) {
          this.fast_curso = objCurso[0];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastAula() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula?id_aula=" + this.$route.params.id_aula + "&id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        console.log("getFastAula", json);
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.fast_aula = obj.find((e) => e.id_aula == this.$route.params.id_aula) || null;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getAulaAtividade() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/lista?id_aula=" + this.$route.params.id_aula, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let objAtividades = Array.from(json);
        //console.log("getAulaAtividade", objAtividades);
        if (objAtividades.length > 0) {
          this.fast_atividades = objAtividades.filter((e) => e.id_aula == this.$route.params.id_aula);
          this.stepList = this.fast_atividades.map((e) => {
            return e.sequencia + " - " + this.formataTipoAtividade(e.tipo_atividade);
          });

          objAtividades.forEach((e, index) => {
            if (e.id_atividade == this.$route.params.id_atividade) {
              this.fast_atividade = e;
              this.fast_atividade.alteracao_pendente = false;
              this.nowStep = index + 1;

              if (this.fast_atividade.tipo_atividade) {
                if (this.fast_atividade.tipo_atividade == "QZ") {
                  this.getQuestoesAtividadeQuiz();
                } else if (this.fast_atividade.tipo_atividade == "PS") {
                  this.getQuestoesAtividadePesquisa();
                } else if (this.fast_atividade.tipo_atividade == "AV") {
                  this.getQuestoesAtividadeAvaliacao();
                  this.getAvaliacoesCurso();
                } else if (this.fast_atividade.tipo_atividade == "LV") {
                  this.getPlataformasUsuarios();
                  this.getFastUsuarioParametro();
                } else if (this.fast_atividade.tipo_atividade == "UP") {
                  this.getFastUsuarioUploads();
                  this.$store.state.fastCarregando = false;
                } else {
                  // Informa ao component pai para interromper o loading
                  this.$store.state.fastCarregando = false;
                }
              } else {
                // Informa ao component pai para interromper o loading
                this.$store.state.fastCarregando = false;
              }
            }
          });
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    formataTipoAtividade(e) {
      switch (e) {
        case "RF":
          return "Arquivo";
          break;
        case "AD":
          return "Audio";
          break;
        case "VA":
          return "Video Aula";
          break;
        case "PS":
          return "Pesquisa de satisfação";
          break;
        case "AV":
          return "Avaliação";
          break;
        case "SM":
          return "Simulado";
          break;
        case "QZ":
          return "Quiz";
          break;
        case "LV":
          return "Live";
          break;
        case "UP":
          return "Upload";
          break;
        default:
          return "Desconhecido";
      }
    },
    selecionaAtividadeStep(item) {
      let id_atividade = this.fast_atividades.filter((e, index) => {
        if (item == index + 1) return e.id_atividade;
      });
      if (id_atividade.length) this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/secretaria/unidade-curricular/" + this.$route.params.id_curso + "/aulas/" + this.$route.params.id_aula + "/atividade/" + id_atividade[0].id_atividade) //window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/secretaria/unidade-curricular/" + this.$route.params.id_curso + "/aulas/" + this.$route.params.id_aula + "/atividade/" + id_atividade[0].id_atividade;
    },
    // Métodos de upload
    fileValidation(e) {
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.fast_atividade.referencia_atividade = false;
        setTimeout(() => {
          this.fast_atividade.referencia_atividade = result.url;
        }, 1000);
      }
    },
    validaVideoVimeo() {
      if (this.nova_referencia_atividade.indexOf("vimeo.com") != "-1") {
        let link = this.nova_referencia_atividade.split("/");
        console.log(link);
        if (link[link.length - 2]) {
          if (link[link.length - 2] == "vimeo.com") {
            this.nova_referencia_atividade = "https://player.vimeo.com/video/" + link[link.length - 1];
          } else if (link[link.length - 3] == "vimeo.com") {
            this.nova_referencia_atividade = "https://player.vimeo.com/video/" + link[link.length - 2];
          }
        }
      }
    },
    validaVideoYoutube() {
      if (this.nova_referencia_atividade.indexOf("watch?v=") != "-1") {
        let link = this.nova_referencia_atividade.split("watch?v=");
        this.nova_referencia_atividade = "https://www.youtube.com/embed/" + link.pop();
      }
    },
    chkIncrementaEvolucao(e) {
      this.fast_atividade.incrementa_evolucao = !this.fast_atividade.incrementa_evolucao;
      this.confirmaAlteracoesAtividades();
    },
    chkAtividadePublicada(e) {
      this.fast_atividade.publicada = !this.fast_atividade.publicada;
      this.confirmaAlteracaoAtividade();
    },
    confirmaAlteracaoAtividade() {
      let fast_curso_aula_atividade = {
        id_atividade: this.fast_atividade.id_atividade,
        id_curso: this.fast_atividade.id_curso,
        id_aula: this.fast_atividade.id_aula,
        titulo_atividade: this.fast_atividade.titulo_atividade,
        detalhe_atividade: this.fast_atividade.detalhe_atividade,
        incrementa_evolucao: this.fast_atividade.incrementa_evolucao,
        publicada: this.fast_atividade.publicada,
        referencia_atividade: this.fast_atividade.referencia_atividade,
        tentativas_maxima: this.fast_atividade.tentativas_maxima,
        tempo_limite: this.fast_atividade.tempo_limite ? (this.fast_atividade.tempo_limite.length != 5 ? this.fast_atividade.tempo_limite : `${this.fast_atividade.tempo_limite}:00`) : null,
      };
      if (this.nova_referencia_atividade) {
        fast_curso_aula_atividade.referencia_atividade = this.nova_referencia_atividade;
      }
      console.log("confirmaAlteracaoAtividade", fast_curso_aula_atividade);
      this.$store.state.fastCarregando = true;
      if(this.fast_atividade.referencia_atividade == "" && (this.fast_atividade.tipo_atividade == "VA" || this.fast_atividade.tipo_atividade == "RF" || this.fast_atividade.tipo_atividade == "AD")){
          this.$store.state.fastCarregando = false;
          this.exibeToasty("A atividade está sem arquivo", "error")
      } else if (this.fast_atividade.tipo_atividade == "QZ" && (this.fastAtividadeQuestoes.questoes == "")) {
         this.$store.state.fastCarregando = false;
          this.exibeToasty("O quiz está sem perguntas.", "error")
      } else if(this.fast_atividade.tipo_atividade == "PS" && (this.fastAtividadePesquisa == "")){
         this.$store.state.fastCarregando = false;
          this.exibeToasty("A pesquisa está sem descrição", "error")
      } else if(this.fast_atividade.tipo_atividade == "AV" && (this.fastAtividadeQuestoes.questoes == "")){
         this.$store.state.fastCarregando = false;
          this.exibeToasty("A atividade está sem opções", "error")
      }
      else{
      fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade/atualiza_novo`, this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula_atividade)))
        .then((resp) => resp.json())
        .then((obj) => {
          console.log(obj);
          // Notificação
          this.exibeToasty("Alterações realizadas com sucesso", "success");
          

          if (this.nova_referencia_atividade) {
            this.hideModal("modalCadastrarVideoYoutube");
            this.fast_atividade.referencia_atividade = false;
            setTimeout(() => {
              fast_curso_aula_atividade.referencia_atividade = this.nova_referencia_atividade;
            }, 1000);
          }
          this.$store.state.fastCarregando = false;
          this.getAulaAtividade();
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.fastCarregando = false;
          // Notificação
          this.exibeToasty("Erro ao salvar alterações", "error")
        });
      }
    },
    confirmaAlteracoesAtividades() {
      
      let fast_curso_aula_atividade = {
        id_atividade: this.fast_atividade.id_atividade,
        id_curso: this.fast_atividade.id_curso,
        id_aula: this.fast_atividade.id_aula,
        titulo_atividade: this.fast_atividade.titulo_atividade,
        detalhe_atividade: this.fast_atividade.detalhe_atividade,
        incrementa_evolucao: this.fast_atividade.incrementa_evolucao,
        publicada: this.fast_atividade.publicada,
        referencia_atividade: this.fast_atividade.referencia_atividade,
        tentativas_maxima: this.fast_atividade.tentativas_maxima,
        tempo_limite: this.fast_atividade.tempo_limite ? (this.fast_atividade.tempo_limite.length != 5 ? this.fast_atividade.tempo_limite : `${this.fast_atividade.tempo_limite}:00`) : null,
      };
      if (this.nova_referencia_atividade) {
        fast_curso_aula_atividade.referencia_atividade = this.nova_referencia_atividade;
      }
      this.$store.state.fastCarregando = true;
      
      fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade/atualiza_novo`, this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula_atividade)))
        .then((resp) => resp.json())
        .then((obj) => {
          console.log(obj);
          // Notificação
          
          this.exibeToasty("Salvo com sucesso", "success");

          if (this.nova_referencia_atividade) {
            this.hideModal("modalCadastrarVideoYoutube");
            this.fast_atividade.referencia_atividade = false;
            setTimeout(() => {
              fast_curso_aula_atividade.referencia_atividade = this.nova_referencia_atividade;
            }, 1000);
          }
          this.$store.state.fastCarregando = false;
          this.getAulaAtividade();
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.fastCarregando = false;
          // Notificação
         this.exibeToasty("Erro ao salvar alterações", "error")
        });
    },
    
    // Método da api do vimeo
    async criaVideoVimeo() {
      // Loading
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/vimeo/cria_video", this.fastAjaxOptions("POST", JSON.stringify(this.fast_atividade)));
        let json = await resp.json();
        console.log("criaVideoVimeo", json);
        let video_uri = json.split(";");
        let video_uri_id = video_uri[1].split("/");
        video_uri_id = video_uri_id.pop();
        // Separa parâmetros do link e elimina o parâmetro redirect_rul
        let link = json.split("?");
        let ticket_id = link[1].split("&");
        ticket_id = ticket_id[0];
        let video_file_id = link[1].split("&");
        video_file_id = video_file_id[1];
        let signature = link[1].split("&");
        signature = signature[2];
        let v6 = link[1].split("&");
        v6 = v6[3];
        let novo_link = link[0] + "?" + ticket_id + "&" + video_file_id + "&" + signature + "&" + v6;

        this.fastNovoVideo = "https://player.vimeo.com/video/" + video_uri_id;
        this.optionsVideo.target = novo_link;
        this.criaAlbumVimeo(video_uri_id);
        this.thisfastVimeoVideoId = video_uri_id;
        // Loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        // Loading
        this.$store.state.fastCarregando = false;
        this.hideModal("modalCadastrarVideoFile");
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Erro",
          text: e,
        });
        console.log("Erro", e);
      }
    },
    vimeoSuccess(rootFile, file, message, chunk) {
      console.log("Upload finalizado");
      this.fast_atividade.referencia_atividade = this.fastNovoVideo;
      this.optionsVideo.target = "";
      this.hideModal("modalCadastrarVideoFile");
      let fast_curso_aula_atividade = {
        id_atividade: this.fast_atividade.id_atividade,
        id_curso: this.fast_atividade.id_curso,
        id_aula: this.fast_atividade.id_aula,
        titulo_atividade: this.fast_atividade.titulo_atividade,
        detalhe_atividade: this.fast_atividade.detalhe_atividade,
        incrementa_evolucao: this.fast_atividade.incrementa_evolucao,
        publicada: this.fast_atividade.publicada,
        referencia_atividade: this.fast_atividade.referencia_atividade,
        tentativas_maxima: this.fast_atividade.tentativas_maxima,
        tempo_limite: this.fast_atividade.tempo_limite ? (this.fast_atividade.tempo_limite.length != 5 ? this.fast_atividade.tempo_limite : `${this.fast_atividade.tempo_limite}:00`) : null,
      };
      fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade/atualiza_novo`, this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula_atividade)))
        .then((resp) => resp.json())
        .then((obj) => {
          
          this.exibeToasty("Aguarde um momento! Seu video está finalizando o upload...", "info")
          this.verificaStatusVideoVimeo(this.thisfastVimeoVideoId);
        })
        .catch((e) => {
          console.log(e);
          // Notificação
          
          this.exibeToasty("Erro ao salvar alterações", "error")
        });
    },
    async criaAlbumVimeo(video_uri_id) {
      let vimeoAlbum = {
        album_name: this.$store.state.fastPlataforma.nome_plataforma,
        video_id: video_uri_id,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/vimeo/cria_album", this.fastAjaxOptions("POST", JSON.stringify(vimeoAlbum)));
        let json = await resp.json();
        console.log("criaAlbumVimeo", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async verificaStatusVideoVimeo(video_uri_id) {
      let vimeoVideo = {
        video_id: video_uri_id,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/vimeo/verifica_status?video_id=" + video_uri_id, this.fastAjaxOptions("POST", JSON.stringify(vimeoVideo)));
        let json = await resp.json();
        console.log("verificaStatusVideoVimeo", json);

        if (json == "uploading") {
          setTimeout(() => {
            this.verificaStatusVideoVimeo(video_uri_id);
          }, 10000);
        } else if (json == "transcoding") {
          this.$notify({
            group: "foo",
            clean: true,
          });
          this.exibeToasty("Seu video está sendo codificado. Esse processo pode demorar alguns minutos, mas não se preocupe, seu video já está salvo e você pode continuar as suas tarefas normalmente enquanto o servidor realiza esse processo!", "info")
          setTimeout(() => {
            this.verificaStatusVideoVimeo(video_uri_id);
          }, 20000);
        } else if (json == "available") {
          this.$notify({
            group: "foo",
            clean: true,
          });
          this.exibeToasty("Seu video está pronto!", "success");


          this.fast_atividade.referencia_atividade = false;
          setTimeout(() => {
            this.fast_atividade.referencia_atividade = this.fastNovoVideo;
          }, 1000);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Quiz
    async getQuestoesAtividadeQuiz() {
      this.fastAtividadeQuestoes.questoes = [];
      this.fastAtividadeQuestoes.tipo_atividade = "quiz";
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_quiz/lista?id_atividade=" + this.$route.params.id_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          //e.questoes = obj;
          const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          questoesUnicas.forEach((a, index) => {
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });
          this.fastAtividadeQuestoes.questoes = questoesUnicas;
        } else {
          this.getQuizQuestoesConcurseiro();
        }
        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
        //console.log(this.fastAtividadeQuestoes);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getQuizQuestoesConcurseiro() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_questao/lista?id_atividade=" + this.$route.params.id_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        //console.log("getQuizQuestoesConcurseiro", json);
        let obj = Array.from(json);
        if (obj.length) {
          this.$toast.open({
            message: "Questões do concurseiro!",
            type: "info",
            duration: 60000,
            dismissible: true,
            position: "bottom-right",
          });
          let questao = {};
          let alternativas = [];
          obj.forEach((q, index) => {
            this.buscaPerguntaConcurseiro(q.id_questao).then((r) => {
              questao = {};
              this.buscaAlternativasConcurseiro(r[0].ID_QUESTAO).then((a) => {
                //console.log("buscaAlternativasConcurseiro", a);
                alternativas = [];
                a.forEach((e) => {
                  alternativas.push({
                    id_alternativa: e.ID_ALTERNATIVA,
                    alternativa: e.ALTERNATIVA,
                    correta: e.CORRETA,
                  });
                });
                questao = {
                  resolvida: q.resolvida,
                  acertou: q.acertou,
                  id_questao: r[0].ID_QUESTAO,
                  pergunta: r[0].PERGUNTA,
                  alternativas: alternativas,
                  questao_concurseiro: true,
                };
                this.fastAtividadeQuestoes.questoes.push(questao);
                //console.log(this.fastAtividadeAtual.quiz);
              });
            });
          });
        } else {
          this.$toast.open({
            message: "Nenhuma questão encontrada",
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async buscaPerguntaConcurseiro(id_questao) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiConcurseiro + "api/concurseiro/questoes/listaquestoeslivre?id_questao=" + id_questao, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    async buscaAlternativasConcurseiro(id_questao) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiConcurseiro + "api/alternativas/questao?id=" + id_questao, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    exibeModalExcluirQuestaoQuiz(questao) {
      this.excluirQuestaoQuiz = questao;
      this.showModal("modalExcluirQuestaoQuiz");
    },
    confirmaExcluirQuestaoQuiz() {
      console.log(this.excluirQuestaoQuiz);
      if (this.excluirQuestaoQuiz.id_questao) {
        // loading
        this.$store.state.fastCarregando = true;
        const model = {
          fast_curso_aula_atividade_quiz: {
            id_questao: this.excluirQuestaoQuiz.id_questao,
            id_atividade: this.excluirQuestaoQuiz.id_atividade,
          },
        };

        this.excluirQuestaoQuiz = [];
        fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade_quiz/exclui`, this.fastAjaxOptions("POST", JSON.stringify(model)))
          .then((resp) => resp.json())
          .then((obj) => {
            this.$notify({
              group: "foo",
              type: "success",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Questão excluída com sucesso",
            });
            this.getQuestoesAtividadeQuiz();
            this.hideModal("modalExcluirQuestaoQuiz");
          })
          .catch((e) => console.log(e));
      }
    },
    // Pesquisa de satisfação
    async getQuestoesAtividadePesquisa() {
      this.fastAtividadePesquisa = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_pesquisa/lista?id_atividade=" + this.$route.params.id_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((e) => (e.alternativas = []));
          this.fastAtividadePesquisa = obj;
          //console.log(this.fastAtividadePesquisa);
          this.fastAtividadePesquisa.forEach((e) => {
            this.getQuestoesAtividadePesquisaRespostas(e);
          });
        }

        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getQuestoesAtividadePesquisaRespostas(pergunta) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_pequisa_resposta/lista?id_pergunta=" + pergunta.id_pergunta, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          pergunta.alternativas = obj;
        }
        //console.log(obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    adicionaAlternativaPesquisaEdicao(id_pergunta) {
      this.fastAtividadePesquisa.forEach((p) => {
        if (p.id_pergunta == id_pergunta) {
          p.alternativas.push({
            id_resposta: 0,
            html_resposta: "",
            estrelas: "",
          });
        }
      });
    },
    atualizaEstrelaPesquisa(pergunta) {
      //console.log(pergunta);
      let model = {};
      let acao = "insere";
      if (pergunta.tipo_pesquisa == "E") {
        if (pergunta.alternativas.length) {
          acao = "atualiza";
          model = {
            fast_curso_aula_atividade_pesquisa: pergunta,
            fast_curso_aula_atividade_pequisa_resposta: {
              id_resposta: pergunta.alternativas[0].id_resposta,
              html_resposta: null,
              estrelas: pergunta.alternativas[0].estrelas,
            },
          };
        } else {
          model = {
            fast_curso_aula_atividade_pesquisa: pergunta,
            fast_curso_aula_atividade_pequisa_resposta: {
              id_resposta: 0,
              html_resposta: null,
              estrelas: pergunta.alternativas[0].estrelas,
            },
          };
        }
      }

      fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade_pesquisa/${acao}`, this.fastAjaxOptions("POST", JSON.stringify(model)))
        .then((resp) => resp.json())
        .then((obj) => {
          // Notificação
          
          this.exibeToasty("Alterações realizadas com sucesso", "success");

          this.getAulaAtividade();
        })
        .catch((e) => {
          console.log(e);
          // Notificação
          this.exibeToasty("Erro ao salvar alterações", "error")
        });
    },
    atualizaPerguntaPesquisa(pergunta) {
      let model = {
        fast_curso_aula_atividade_pesquisa: pergunta,
        fast_curso_aula_atividade_pequisa_resposta: {
          id_resposta: 0,
          html_resposta: null,
          estrelas: 0,
        },
      };
      fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade_pesquisa/atualiza`, this.fastAjaxOptions("POST", JSON.stringify(model)))
        .then((resp) => resp.json())
        .then((obj) => {
          // Notificação
         
          this.exibeToasty("Alterações realizadas com sucesso", "success");
        })
        .catch((e) => {
          console.log(e);
          // Notificação
          this.exibeToasty("Erro ao salvar alterações", "error")
        });

      if (pergunta.alternativas.length) {
        pergunta.alternativas.forEach((e) => {
          model = {
            fast_curso_aula_atividade_pesquisa: pergunta,
            fast_curso_aula_atividade_pequisa_resposta: e,
          };
          this.atualizaAlternativaPesquisa(model);
        });
      }
    },
    criaFastPesquisa() {
      this.novaPesquisa.id_atividade = this.$route.params.id_atividade;
      let model = {
        fast_curso_aula_atividade_pesquisa: this.novaPesquisa,
        fast_curso_aula_atividade_pequisa_resposta: {
          id_resposta: 0,
          html_resposta: null,
          estrelas: this.novaPesquisa.alternativas[0].estrelas,
        },
      };
      if (this.novaPesquisa.tipo_pesquisa == "E") {
        fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade_pesquisa/insere`, this.fastAjaxOptions("POST", JSON.stringify(model)))
          .then((resp) => resp.json())
          .then((obj) => {
            // Notificação
            this.$notify({
              group: "foo",
              type: "success",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Pesquisa inserida com sucesso",
            });

            this.getAulaAtividade();
            this.hideModal("modalCadastrarPesquisa");
          })
          .catch((e) => {
            console.log(e);
            // Notificação
            this.$notify({
              group: "foo",
              type: "error",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Erro ao salvar pesquisa",
            });
          });
      } else if (this.novaPesquisa.tipo_pesquisa == "O") {
        model.fast_curso_aula_atividade_pequisa_resposta = {};

        fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade_pesquisa/insere`, this.fastAjaxOptions("POST", JSON.stringify(model)))
          .then((resp) => resp.json())
          .then((obj) => {
            if (obj.length > 0) {
              // Notificação
              this.$notify({
                group: "foo",
                type: "success",
                duration: 5000,
                speed: 1000,
                title: "Status",
                text: "Pesquisa inserida com sucesso",
              });

              this.getAulaAtividade();
              this.hideModal("modalCadastrarPesquisa");

              model.fast_curso_aula_atividade_pesquisa.id_pergunta = obj[0].id_pergunta;
              this.novaPesquisa.alternativas.forEach((e) => {
                if (e.html_resposta) {
                  model = {
                    fast_curso_aula_atividade_pesquisa: this.novaPesquisa,
                    fast_curso_aula_atividade_pequisa_resposta: {
                      id_resposta: 0,
                      html_resposta: e.html_resposta,
                      estrelas: 0,
                    },
                  };
                  this.cadastraAlternativaPesquisa(model);
                }
              });
            }
          })
          .catch((e) => {
            console.log(e);
            // Notificação
            this.$notify({
              group: "foo",
              type: "error",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Erro ao salvar pesquisa",
            });
          });
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Selecione o tipo de pesquisa",
        });
      }
    },
    async cadastraAlternativaPesquisa(model) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_pesquisa/insere", this.fastAjaxOptions("POST", JSON.stringify(model)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async atualizaAlternativaPesquisa(model) {
      let acao = "insere";
      if (model.fast_curso_aula_atividade_pequisa_resposta.id_resposta) acao = "atualiza";
      if (model.fast_curso_aula_atividade_pequisa_resposta.html_resposta) {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_pesquisa/" + acao, this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length > 0) {
          }
        } catch (e) {
          console.log("Erro", e);
        }
      } else {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_pequisa_resposta/exclui", this.fastAjaxOptions("POST", JSON.stringify(model.fast_curso_aula_atividade_pequisa_resposta)));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length > 0) {
          }
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    exibeModalExcluirPesquisa(pesquisa) {
      this.showModal("modalExcluirPesquisa");
      this.excluirPesquisa.push(pesquisa);
    },
    confirmaExcluirPesquisa() {
      if (this.excluirPesquisa.length > 0) {
        // loading
        this.$store.state.fastCarregando = true;
        let fast_curso_aula_atividade_pesquisa = {
          id_atividade: this.excluirPesquisa[0].id_atividade,
          id_pergunta: this.excluirPesquisa[0].id_pergunta,
        };
        this.excluirPesquisa = [];
        fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade_pesquisa/exclui`, this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula_atividade_pesquisa)))
          .then((resp) => resp.json())
          .then((obj) => {
            this.$notify({
              group: "foo",
              type: "success",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Pesquisa excluída com sucesso",
            });
            this.getAulaAtividade();
            this.hideModal("modalExcluirPesquisa");
          })
          .catch((e) => console.log(e));
      }
    },
    // Avaliação/prova
    async getQuestoesAtividadeAvaliacao() {
      this.fastAtividadeQuestoes.questoes = [];
      this.fastAtividadeQuestoes.tipo_atividade = "avaliacao";
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_prova/lista_novo?id_atividade=" + this.$route.params.id_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          //e.questoes = obj;
          const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          questoesUnicas.forEach((a, index) => {
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });
          this.fastAtividadeQuestoes.questoes = questoesUnicas;
        } else {
          this.fastAtividadeQuestoes.questoes = [];
        }
        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
        //console.log(this.fastAtividadeQuestoes);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async atualizaPesoQuestaoAvaliacao(questao) {
      let fast_curso_aula_atividade_prova = {
        id_atividade: this.fast_atividade.id_atividade,
        id_questao: questao.id_questao,
        questao_peso: questao.questao_peso,
      };

      fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade_prova/atualiza_nota`, this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula_atividade_prova)))
        .then((resp) => resp.json())
        .then((obj) => {
          this.getAvaliacoesCurso();
          // Notificação
          
          this.exibeToasty("Peso da questão atualizado com sucesso", "success");
        })
        .catch((e) => {
          console.log(e);
          // Notificação
          this.exibeToasty("Erro ao atualizar peso da questão", "error")
        });
    },
    async getAvaliacoesCurso() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_prova/lista_provas?id_curso=" + this.$route.params.id_curso + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((e, index) => {
            e.porcentagem = (e.nota_prova * 100) / e.nota_maxima;
            e.cor = this.cores[index];

            if (e.id_atividade == this.$route.params.id_atividade) {
              this.fastAvaliacaoAtual = e;
              if (!this.fastAvaliacaoAtual.nota_maxima) this.fastAvaliacaoAtual.nota_maxima = this.fastAvaliacaoAtual.nota_prova;
              this.fastAvaliacaoAtual.porcentagem = (this.fastAvaliacaoAtual.nota_prova * 100) / this.fastAvaliacaoAtual.nota_maxima;
            }
          });
          this.fastAvaliacoesCurso = obj;
        } else {
          this.fastAvaliacoesCurso = [
            {
              nota_maxima: null,
              nota_media: null,
              porcentagem: 0,
              nota_prova: 0,
              cor: "success",
            },
          ];
        }
        //console.log(json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async atualizaNotasCurso() {
      if (this.fastAvaliacaoAtual.nota_maxima && this.fastAvaliacaoAtual.nota_media) {
        let fast_curso_plataforma = {
          id_curso: this.$route.params.id_curso,
          id_plataforma: this.$route.params.id_plataforma,
          nota_maxima: this.fastAvaliacaoAtual.nota_maxima,
          nota_media: this.fastAvaliacaoAtual.nota_media,
        };
        fetch(`${settings.endApiFastEad}api/fast_curso_plataforma/atualiza_notas`, this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma)))
          .then((resp) => resp.json())
          .then((obj) => {
            this.getAvaliacoesCurso();
            // Notificação
            this.$notify({
              group: "foo",
              type: "success",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Notas do curso atualizadas com sucesso",
            });
          })
          .catch((e) => {
            console.log(e);
            // Notificação
            this.$notify({
              group: "foo",
              type: "error",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Erro ao atualizar notas do curso",
            });
          });
      } else {
        // Notificação
        this.$notify({
          group: "foo",
          type: "info",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Insira a nota média e máxima do curso",
        });
      }
    },
    calculaPesoTotalAvaliacoes() {
      let total = 0;
      this.fastAvaliacoesCurso.forEach((e) => (total += e.nota_prova));
      return total;
    },
    exibeModalExcluirQuestaoAvaliacao(questao) {
      this.excluirQuestaoAvaliacao = questao;
      this.showModal("modalExcluirQuestaoAvaliacao");
    },
    confirmaExcluirQuestaoAvaliacao() {
      console.log(this.excluirQuestaoAvaliacao);
      if (this.excluirQuestaoAvaliacao.id_questao) {
        // loading
        this.$store.state.fastCarregando = true;
        let fast_curso_aula_atividade_prova = {
          id_questao: this.excluirQuestaoAvaliacao.id_questao,
          id_atividade: this.excluirQuestaoAvaliacao.id_atividade,
        };
        this.excluirQuestaoAvaliacao = [];
        fetch(`${settings.endApiFastEad}api/fast_curso_aula_atividade_prova/exclui_novo`, this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula_atividade_prova)))
          .then((resp) => resp.json())
          .then((obj) => {
            this.$notify({
              group: "foo",
              type: "success",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Questão excluída com sucesso",
            });
            this.getQuestoesAtividadeAvaliacao();
            this.hideModal("modalExcluirQuestaoAvaliacao");
          })
          .catch((e) => console.log(e));
      }
    },
    // Criação de atividade
    exibeModalCriarAtividadeTipo(tipo_atividade) {
      this.novaAtividade.tipo_atividade = tipo_atividade;
      this.showModal("modalCriarAtividadeTipo", "modalCriarAtividade");
    },
    validaCriarAtividade() {
      let erros = [];
      if (!this.novaAtividade.titulo_atividade.length) erros.push("O título da atividade não pode ficar em branco");
      if (!this.novaAtividade.tipo_atividade.length) erros.push("O tipo da atividade não foi identificado");
      this.novaAtividade.id_aula = this.$route.params.id_aula;
      this.novaAtividade.id_curso = this.$route.params.id_curso;

      if (erros.length) {
        erros.forEach((e) => {
          this.exibeToasty(e, "error")
        });
      } else {
        this.criarAtividade();
      }
    },
    async criarAtividade() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/insere", this.fastAjaxOptions("POST", JSON.stringify(this.novaAtividade)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/secretaria/unidade-curricular/" + this.$route.params.id_curso + "/aulas/" + obj[0].id_aula + "/atividade/" + obj[0].id_atividade;
        } else {
          this.exibeToasty("Erro ao criar atividade", "error")
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async excluirAtividade() {
      const fast_curso_aula_atividade = {
        id_atividade: this.$route.params.id_atividade,
        id_aula: this.$route.params.id_aula,
        id_curso: this.$route.params.id_curso,
      };

      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/exclui", this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula_atividade)));
        let json = await resp.json();
        let obj = Array.from(json);

        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Atividade excluída com sucesso",
        });
        this.hideModal("modalExcluirAtividade");
        if (this.fast_atividades.length > 1) {
          this.fast_atividades.forEach((a) => {
            if (a.id_atividade != this.$route.params.id_atividade) window.location.href = `/plataforma/${this.$route.params.id_plataforma}/secretaria/unidade-curricular/${this.$route.params.id_curso}/aulas/${this.$route.params.id_aula}/atividade/${a.id_atividade}`;
          });
        } else {
          window.location.href = `/plataforma/${this.$route.params.id_plataforma}/secretaria/unidade-curricular/${this.$route.params.id_curso}/aulas`;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Live
    async getFastLiveAtividade() {
      if (this.fast_atividade.referencia_atividade) {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_live/lista_usuario_nova?id_live=" + this.fast_atividade.referencia_atividade, this.fastAjaxOptions("GET"));
          let obj = await resp.json();
          console.log("getFastLiveAtividade", obj);
          if (obj.length) {
            let lives = Array.from(new Set(obj.map((a) => a.id_live))).map((id_live) => {
              return obj.find((a) => a.id_live === id_live);
            });
            lives.forEach((live, index) => {
              if (!live.participantes) live.participantes = [];
              obj.forEach((p, index2) => {
                if (live.id_live == p.id_live) live.participantes.push(p.id_usuario);
              });
            });

            //console.log(this.fastLive);

            let participantes = [];
            this.usuariosTotal.forEach((u) => {
              lives[0].participantes.forEach((e, index) => {
                if (e.id_usuario) {
                  if (e.id_usuario == u.id_usuario) {
                    participantes.push(u);
                  }
                } else {
                  if (e == u.id_usuario) {
                    participantes.push(u);
                  }
                }
              });
            });
            lives[0].participantes = participantes;
            this.fastLive = lives[0];
          } else {
            this.$toast.open({
              message: "Nenhuma live encontrada",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
          }

          this.$store.state.fastCarregando = false;
          //console.log("getFastLive", this.fastLiveTotal);
        } catch (e) {
          console.log("Erro", e);
        }
      } else {
        this.$toast.open({
          message: "Nenhuma live agendada",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        this.$store.state.fastCarregando = false;
      }
    },
    async criarLive() {
      let erros = [];
      if (!this.fastLive.id_usuario_tutor) erros.push("A escolha do tutor é obrigatória");
      if (!this.fastLive.titulo_live) erros.push("O título da live é obrigatório");
      if (!this.fastLive.detalhes) this.fastLive.detalhes = "Sem detalhes";
      if (!this.fastLive.data_live) erros.push("O data da live é obrigatória");
      if (!this.fastLive.hora_live) erros.push("O hora da live é obrigatória");
      if (!this.fastLive.duracao_live) erros.push("O duração da live é obrigatória");
      this.fastLive.nr_maximo_sala = 0;
      if (!this.fastLive.participantes.length) erros.push("Ao menos um participante é obrigatório");

      if (this.fastLive.tipo == "Z" && !this.fastUsuarioParametro.url_zoom) erros.push("É necessário configurar a URL do Zoom para pode agendar esse tipo de live");

      if (this.fastLive.tipo == "Z") this.fastLive.API_TOKEN = this.fastUsuarioParametro.url_zoom;

      if (this.fastLive.tipo == "M" && !this.fastLive.API_TOKEN) erros.push("É necessário inserir a URL do Google Meet");

      if (erros.length) {
        erros.forEach((e) => {
          this.exibeToasty(e, "error")
        });
      } else {
        this.$store.state.fastCarregando = true;

        this.fastLive.id_usuario_moderador = this.fastLive.id_usuario_tutor;
        this.fastLive.id_plataforma = this.$route.params.id_plataforma;
        this.fastLive.status = "A";
        let id_usuarios_participantes = this.fastLive.participantes.map((u) => u.id_usuario);
        let email_usuarios_participantes = this.fastLive.participantes.map((u) => u.email);
        let model = {
          fast_plataforma_live: this.fastLive,
          id_usuarios_participantes: id_usuarios_participantes.join(),
          email_usuarios_participantes: email_usuarios_participantes.join(),
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_live/insere_nova", this.fastAjaxOptions("POST", JSON.stringify(model)));

          let json = await resp.json();
          let obj = Array.from(json);
          //console.log(json);
          this.nova_referencia_atividade = obj[0].id_live;
          this.confirmaAlteracoesAtividades();
          this.exibeToasty("Live agendada com sucesso", "success");
          
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
        }
      }
    },
    async editarLive() {
      let erros = [];
      if (!this.fastLive.id_usuario_tutor) erros.push("A escolha do tutor é obrigatória");
      if (!this.fastLive.titulo_live) erros.push("O título da live é obrigatório");
      if (!this.fastLive.detalhes) this.fastLive.detalhes = "Sem detalhes";
      if (!this.fastLive.data_live) erros.push("O data da live é obrigatória");
      if (!this.fastLive.hora_live) erros.push("O hora da live é obrigatória");
      if (!this.fastLive.duracao_live) erros.push("O duração da live é obrigatória");
      this.fastLive.nr_maximo_sala = 0;
      if (!this.fastLive.participantes.length) erros.push("Ao menos um participante é obrigatório");
      if (this.fastLive.tipo == "Z" && !this.fastUsuarioParametro.url_zoom) erros.push("É necessário configurar a URL do Zoom para pode agendar esse tipo de live");
      if (this.fastLive.tipo == "M" && !this.fastLive.API_TOKEN) erros.push("É necessário inserir a URL do Google Meet");

      if (erros.length) {
        erros.forEach((e) => {
          this.exibeToasty(e, "error")
        });
      } else {
        this.$store.state.fastCarregando = true;

        this.fastLive.id_usuario_moderador = this.fastLive.id_usuario_tutor;
        this.fastLive.id_plataforma = this.$route.params.id_plataforma;
        this.fastLive.status = "A";
        let id_usuarios_participantes = this.fastLive.participantes.map((u) => u.id_usuario);
        let model = {
          fast_plataforma_live: this.fastLive,
          id_usuarios_participantes: id_usuarios_participantes.join(),
        };

        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_live/atualiza_nova", this.fastAjaxOptions("POST", JSON.stringify(model)));

          let json = await resp.json();
          let obj = Array.from(json);
          console.log(json);
          
          this.exibeToasty("Live atualizada com sucesso", "success");

          
          this.$store.state.fastCarregando = false;
          this.getAulaAtividade();
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
        }
      }
    },
    formataDataLive(data_live) {
      if (data_live.split("T").length > 1) {
        data_live = data_live.split("T")[0];
      }
      data_live = data_live.split("-")[2] + "/" + data_live.split("-")[1] + "/" + data_live.split("-")[0];

      return data_live;
    },
    async getPlataformasUsuarios() {
      let param = `id_plataforma=${this.$route.params.id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=`
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/lista_usuarios_plataforma?" + param, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        console.log("getPlataformasUsuarios", obj);
        if (obj.length > 0) {
          obj.forEach((e) => {
            if (!e.nome_usuario) e.nome_usuario = "Não cadastrado";
          });
          this.usuariosTotal = obj;
          this.usuariosFiltro = obj;
        } else {
          this.usuariosTotal = [];
          this.usuariosFiltro = [];
        }
        this.getFastLiveAtividade();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraUsuario(e) {
      let text = e.target.value;
      this.usuariosFiltro = this.usuariosTotal.filter((e) => e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1");
    },
    adicionaParticipantesLive(usuario) {
      this.fastLive.participantes.push(usuario);
    },
    verificaUsuarioIncluido(id_usuario) {
      let verificacao = this.fastLive.participantes.filter((e) => e.id_usuario == id_usuario);
      if (verificacao.length > 0) return true;
      else return false;
    },
    removeParticipantesLive(id_usuario) {
      this.fastLive.participantes.forEach((u, index) => {
        if (u.id_usuario == id_usuario) this.fastLive.participantes.splice(index, 1);
      });
    },

    // Parametros do usuario
    async getFastUsuarioParametro() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_parametro/lista", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastUsuarioParametro = obj[0];
        } else {
          this.fastUsuarioParametro = {
            id_usuario: "",
            image: "",
            url_zoom: "",
          };
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    redirecionaPerfil() {
      window.location.href = "/meu-perfil";
    },
    // Upload
    async getFastUsuarioUploads() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_aula_atividade_upload_professor/lista?id_atividade=" + this.fast_atividade.id_atividade + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        //console.log("getPlataformasUsuarios", json);
        if (obj.length) {
          const uploads = Array.from(new Set(obj.map((a) => a.id_usuario_upload))).map((id_usuario_upload) => {
            return obj.find((a) => a.id_usuario_upload === id_usuario_upload);
          });

          obj.forEach((u, index) => {
            uploads.forEach((f, index) => {
              if (u.id_usuario_upload == f.id_usuario_upload) {
                if (!f.anexos) f.anexos = [];
                f.anexos.push(u);
              }
            });
          });

          const uploads_usuario = Array.from(new Set(obj.map((a) => a.id_usuario))).map((id_usuario) => {
            return obj.find((a) => a.id_usuario === id_usuario);
          });

          uploads.forEach((u, index) => {
            uploads_usuario.forEach((f, index) => {
              if (u.id_usuario == f.id_usuario) {
                if (!f.envios) f.envios = [];
                f.envios.push(u);
              }
            });
          });

          this.fastUploads = uploads_usuario;
          //console.log("this.fastUploads", this.fastUploads);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalEnvios(usuario) {
      this.fastUploadsAlunoEnvios = usuario;
      this.showModal("modalEnvios");
    },
    retornaCorrecoes(envios) {
      let corrigidos = 0;
      envios.forEach((e) => {
        if (e.corrigido == "S") corrigidos++;
      });
      return corrigidos;
    },
    exibeModalUploadEnvioAluno(envio) {
      this.fastUploadsAlunoEnvio = envio;
      this.optionsArquivoResposta.query.id_atividade = this.fast_atividade.id_atividade;
      if (envio.corrigido == "S") {
        this.$store.state.fastCarregando = true;
        this.getFastUsuarioUploadsProfessor(envio.id_usuario_upload)
          .then((e) => {
            this.$store.state.fastCarregando = false;
            this.fastUsuarioUploadProfessor = e[0];
            this.hideModal("modalEnvios");
            this.showModal("modalEnvio");
            console.log("this.fastUsuarioUploadProfessor", this.fastUsuarioUploadProfessor);
          })
          .catch((e) => {
            this.$store.state.fastCarregando = false;
            console.log("Erro", e);
          });
      } else {
        this.fastUsuarioUploadProfessor = {
          id_usuario_upload: "",
          data_hora: "",
          html_texto: "",
          anexos: [],
        };
        this.hideModal("modalEnvios");
        this.showModal("modalEnvio");
      }
    },
    fileSuccessResposta(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.fastUsuarioUploadNovo.paths.push(result.url);
      }
    },
    async getFastUsuarioUploadsProfessor(id_usuario_upload) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_aula_atividade_upload_professor/lista_respostas?id_usuario_upload=" + id_usuario_upload + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
          let obj = await resp.json();
          console.log("getFastUsuarioUploadsProfessor", obj);
          if (obj.length) {
            const uploads = Array.from(new Set(obj.map((a) => a.id_usuario_upload))).map((id_usuario_upload) => {
              return obj.find((a) => a.id_usuario_upload === id_usuario_upload);
            });

            obj.forEach((u, index) => {
              uploads.forEach((f, index) => {
                if (u.id_usuario_upload == f.id_usuario_upload) {
                  if (!f.anexos) f.anexos = [];
                  f.anexos.push(u);
                }
              });
            });

            resolve(uploads);
          }
          reject("Erro");
        } catch (e) {
          reject(e);
        }
      });
    },
    async insereFastUsuarioUploadProfessor() {
      this.$store.state.fastCarregando = true;
      if (!this.fastUsuarioUploadNovo.html_texto) this.fastUsuarioUploadNovo.html_texto = "Sem detalhes";

      let model = {
        fast_usuario_aula_atividade_upload_professor: {
          id_usuario_upload: this.fastUploadsAlunoEnvio.id_usuario_upload,
          html_texto: this.fastUsuarioUploadNovo.html_texto,
        },
        paths: this.fastUsuarioUploadNovo.paths.join(),
      };

      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_aula_atividade_upload_professor/insere", this.fastAjaxOptions("POST", JSON.stringify(model)));
        let obj = await resp.json();
        this.$toast.open({
          message: "Resposta enviada com sucesso",
          type: "success",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        this.fastUsuarioUploadNovo = {
          id_usuario_atividade: "",
          html_texto: "",
          paths: [],
        };
        this.hideModal("modalEnvio");
        this.getFastUsuarioUploads();
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    // Migração de questões
    async migrarQuestoesFastEAD(questoes) {
      this.showModal("modalStatusMigracaoQuestoes");

      let id_prova = 0;
      this.fastStatusMigracaoQuestoes = "Buscando provas...";
      this.getFastPlataformaProvas().then((p) => {
        if (p.length) {
          console.log(p.length + " provas encontradas");
          p.forEach((provas) => {
            if (provas.nome_prova == this.fast_curso.nome_curso) {
              id_prova = provas.id_prova;
            }
          });
          if (id_prova) {
            console.log("Prova já existe...");
            this.buscaQuestoesMigracao(questoes, id_prova);
          } else {
            console.log("Prova não existe...");
            this.criaProva(questoes);
          }
        } else {
          console.log("Nenhum prova encontrada");
          this.criaProva(questoes);
        }
      });
    },
    async getFastPlataformaProvas() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_prova/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length > 0) {
            resolve(json);
          } else {
            resolve(0);
          }
        } catch (e) {
          console.log("Erro", e);
        }
      });
    },
    criaProva(questoes) {
      let id_prova = 0;
      this.fastStatusMigracaoQuestoes = "Criando prova...";
      let fast_plataforma_prova = {
        nome_prova: this.fast_curso.nome_curso,
        id_plataforma: this.$route.params.id_plataforma,
      };
      fetch(`${settings.endApiFastEad}api/fast_plataforma_prova/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_prova)))
        .then((resp) => resp.json())
        .then((obj) => {
          if (obj.length > 0) {
            id_prova = obj[0].id_prova;
            this.fastStatusMigracaoQuestoes = "Prova criada... #" + id_prova;
            this.buscaQuestoesMigracao(questoes, id_prova);
          }
        })
        .catch((e) => {
          this.fastStatusMigracaoQuestoes = "Erro ao criar prova..." + e;
        });
    },
    async buscaQuestoesMigracao(questoes, id_prova) {
      this.fastStatusMigracaoQuestoes = "Buscando questões...";
      let questao = {};
      let alternativas = [];
      questoes.forEach((q) => {
        this.buscaPerguntaConcurseiro(q.id_questao).then((r) => {
          questao = {};
          this.buscaAlternativasConcurseiro(r[0].ID_QUESTAO).then((a) => {
            //console.log("buscaAlternativasConcurseiro", a);
            alternativas = [];
            a.forEach((e) => {
              alternativas.push({
                id_alternativa: e.ID_ALTERNATIVA,
                alternativa: e.ALTERNATIVA,
                correta: e.CORRETA,
              });
            });
            questao = {
              id_questao: r[0].ID_QUESTAO,
              pergunta: r[0].PERGUNTA,
              alternativas: alternativas,
              questao_concurseiro: true,
            };
            this.fastStatusMigracaoQuestoes = "Inserindo questão #" + questao.id_questao + " na prova #" + id_prova;

            this.cadastraQuestaoMigracao(questao, id_prova).then((id_questao_novo) => {
              if (id_questao_novo) {
                console.log("Questão migrada com sucesso #" + id_questao_novo);
                this.fastStatusMigracaoQuestoes = "Questão migrada com sucesso #" + id_questao_novo + ". Questão antiga: #" + questao.id_questao;

                this.atualizaQuestoesAtividade(questao.id_questao, id_questao_novo, this.$route.params.id_atividade).then((resposta) => {
                  console.log("Questão atualizada na atividade com sucesso #" + id_questao_novo);
                });
              } else {
                console.log("Falha ao cadastrar questão");
                this.fastStatusMigracaoQuestoes = "Falha ao cadastrar questão";
              }
            });
          });
        });
      });
    },
    async cadastraQuestaoMigracao(questao_concurseiro, id_prova) {
      return new Promise(async (resolve, reject) => {
        let fast_plataforma_questao = {
          id_prova: id_prova,
          pergunta: questao_concurseiro.pergunta,
          id_plataforma: this.$route.params.id_plataforma,
        };
        fetch(`${settings.endApiFastEad}api/fast_plataforma_questao/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao)))
          .then((resp) => resp.json())
          .then((obj) => {
            if (obj.length > 0) {
              let id_questao = obj[0].id_questao;
              if (id_questao) {
                // Insere alternativas
                questao_concurseiro.alternativas.forEach((a, index) => {
                  if (a.alternativa != "") {
                    let fast_plataforma_alternativa = {
                      id_questao: id_questao,
                      alternativa: a.alternativa,
                      correta: a.correta,
                      fast_plataforma_questao: {
                        id_plataforma: this.$route.params.id_plataforma,
                      },
                    };
                    fetch(`${settings.endApiFastEad}api/fast_plataforma_alternativa/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_alternativa)))
                      .then((resp) => resp.json())
                      .then((obj) => {
                        if (obj.length > 0) {
                        }
                      });
                  }
                  // Última repetição
                  if (index == questao_concurseiro.alternativas.length - 1) {
                  }
                });

                resolve(id_questao);
              } else {
                reject(0);
              }
            } else {
              reject(0);
            }
          })
          .catch((e) => reject(e));
      });
    },
    async atualizaQuestoesAtividade(id_questao_concurseiro, id_questao_fastead, id_atividade) {
      let model = {
        id_questao_concurseiro: id_questao_concurseiro,
        id_questao_fastead: id_questao_fastead,
        id_atividade: id_atividade,
      };
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_quiz/migracao", this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length > 0) {
            resolve(json);
          } else {
            resolve(0);
          }
        } catch (e) {
          console.log("Erro", e);
        }
      });
    },
    // Estatísticas
    async getFastAulaAtividadeEstatisticas() {
      this.fastAulaEstatisticaCarregou = false;
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/lista_estatisticas?id_plataforma=" + this.$route.params.id_plataforma + "&id_atividade=" + this.$route.params.id_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        console.log(json);
        if (obj.length) {
          this.fastAulaEstatisticasFiltro = obj;
          this.fastAulaEstatisticasTotal = obj;
        } else {
          this.fastAulaEstatisticasFiltro = [];
          this.fastAulaEstatisticasTotal = [];
        }
        this.fastAulaEstatisticaCarregou = true;
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    // Desempenho do aluno
    async exibeModalDesempenhoAluno(tipo_atividade, usuario) {
      this.fastUsuarioDesempenho = usuario;
      switch (tipo_atividade) {
        case "AV":
          this.getResultadoProva(usuario.id_usuario)
            .then((e) => {
              let obj = Array.from(e);
              if (obj.length) {
                let acertos = 0;
                let erros = 0;
                let nota = 0;
                let peso_total = 0;
                obj.forEach((q) => {
                  peso_total += q.questao_peso;
                  if (q.acertou != "S") {
                    erros++;
                  } else {
                    nota += q.questao_peso;
                    acertos++;
                  }
                });

                this.fastPesoAvaliacao = peso_total ? parseFloat(peso_total).toFixed(2) : 0
                this.fastNotaAvaliacao = nota ? parseFloat(nota).toFixed(2) : 0
                this.chartDataProva = [acertos, erros];
                this.chartDataProvaPeso = [nota, peso_total - nota];
              }
              this.showModal("modalDesempenhoProvaAluno");
            })
            .catch((e) => console.log(e));
          break;
        case "PS":
          this.getResultadoPesquisa(usuario.id_usuario)
            .then((e) => {
              let obj = Array.from(e);
              if (obj.length) {
                const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_pergunta))).map((id_pergunta) => {
                  return obj.find((a) => a.id_pergunta === id_pergunta);
                });

                questoesUnicas.forEach((a, index) => {
                  obj.forEach((q, index2) => {
                    if (a.id_pergunta == q.id_pergunta) {
                      if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                      questoesUnicas[index].alternativas.push(q);
                    }
                  });
                });

                // Verifica qual questão já resolvidas
                questoesUnicas.forEach((p, index) => {
                  let resolvida = false;
                  if (p.alternativas.length) {
                    p.alternativas.forEach((a) => {
                      if (a.opcao_escolhida == "S" || a.estrelas_escolhida) resolvida = true;
                    });
                  }
                  p.resolvida = resolvida;
                });

                this.fastPesquisaResultado = questoesUnicas;
                console.log("this.fastPesquisaResultado", this.fastPesquisaResultado);
              }
              
              this.showModal("modalResultadoPesquisaAluno");
            })
            .catch((e) => console.log(e));
          break;
        default:
          break;
      }
    },
    async getResultadoProva(id_usuario) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_prova/desempenho?id_atividade=" + this.$route.params.id_atividade + "&id_plataforma=" + this.$route.params.id_plataforma + "&id_usuario_aluno=" + id_usuario, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    async getResultadoPesquisa(id_usuario) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_pesquisa/resultado?id_atividade=" + this.$route.params.id_atividade + "&id_plataforma=" + this.$route.params.id_plataforma + "&id_usuario_aluno=" + id_usuario, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    async getRespostasProva(id_usuario_atividade, id_usuario_aluno){
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/prova/lista_resultado_alunos", `id_usuario_atividade=${id_usuario_atividade}&id_usuario_aluno=${id_usuario_aluno}`)
        .then((obj) => {
          console.log("getRespostasProva", obj)
          if (obj.length) {
            const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
              return obj.find((a) => a.id_questao === id_questao);
            });

            questoesUnicas.forEach((a, index) => {
              obj.forEach((q, index2) => {
                if (a.id_questao == q.id_questao) {
                  if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                  questoesUnicas[index].alternativas.push(q);
                }
              });
            });
            this.fastRespostasAvaliacao = questoesUnicas
            this.showModal('modalRespostasAvaliacao')
          } else {
            this.exibeToasty("Nenhuma resposta encontrada", "error")
          }
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error")
          this.$store.state.fastCarregando = false;
        });
    },
    retornaLetraAlternativa(index) {
      const letra = ["a", "b", "c", "d", "e", "f"];
      return letra[index];
    },
    filtraAlunos(e) {
      let text = e.target.value;
      this.fastAulaEstatisticasFiltro = this.fastAulaEstatisticasTotal.filter((e) => {
        if (e.nome_usuario)
        return e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1";
      });
    },
    exibeModalAlterarTentativa(usuario) {
      this.fastUsuarioAtividadeTentativa = usuario
      this.showModal('modalAlterarTentativa')
    },
    async confirmaAlterarTentativa(){
      console.log("this.fastUsuarioAtividadeTentativa", this.fastUsuarioAtividadeTentativa)
      if (this.fastUsuarioAtividadeTentativa.count > this.fastUsuarioAtividadeTentativa.tentativas_maxima) {
        this.exibeToasty("A tentativa máxima não pode ser inferior ao número de vezes que o aluno realizou", "error")
      } else {
        this.promisePostFastApi(this.fastUsuarioAtividadeTentativa, "api/fast_usuario_curso_aula_atividade/prova/atualiza_tentativa")
          .then((res) => {
            this.getFastAulaAtividadeEstatisticas()
            this.exibeToasty("Tentativa alterada com sucesso", "success");
            this.hideModal("modalAlterarTentativa");
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
          });      
      }
    }
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
/* Iframe do youtube */
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}

/* Tabs */
.tabs-component {
  margin: 1em 0;
  width: 100%;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 1em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
/** Alternativas */
.alt-correta-true {
  background-color: rgb(62, 255, 111);
}
/* Starts */
.vue-star-rating {
  margin: auto;
}

/* Modal de respostas da avlaiação */
.fast-aula-alternativa-escolhida {
  background-color: rgb(192, 192, 192);
}
.fast-aula-alternativa-escolhida-correta {
  background-color: rgb(82, 255, 97);
}
.fast-aula-alternativa-escolhida-incorreta {
  background-color: rgb(255, 58, 58);
}
</style>
